export const trimText = (text: string, maxLength: number) => {
  if (!text) return null;

  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

export const isTextEllipsized = (element: any) => {
  if (!element) {
    return false;
  }

  return (
    element.offsetWidth < element.scrollWidth ||
    element.offsetHeight < element.scrollHeight
  );
};

/**
 * Generates initials. If `lastname` is not provided, `name` is splited on space.
 *
 * @param name - `name` represents the Full Name or First name of the user.
 * @param lastname - (optional) `lastname` represents the Last Name of the user.
 * @returns name initials.
 */

export const generateInitials = (name: string, lastName?: string) => {
  if (!name?.trim() && !lastName?.trim()) return null;

  let firstName = name || "";
  if (!lastName) {
    [firstName, lastName = ""] = name?.split(" ") || [];
  }

  return `${firstName.substring(0, 1).toUpperCase()}${lastName
    .substring(0, 1)
    .toUpperCase()}`;
};
