import APP_FEATURES from "$/settings/app-features.json";
import { getEnums } from "~/utils";

export const APP_FEATURES_ENUM = getEnums(APP_FEATURES, "reference");

// Responsive Breakpoints
export const SMALL_DEVICE_WIDTH = 1024;
export const MOBILE_UI_MAX_WIDTH = 767;

// Events
export const LOGIN = "Login";
export const LOGIN_FAILED = "Login Failed";
export const FORGOT_PASSWORD = "Forgot Password";
export const FORGOT_PASSWORD_FAILED = "Forgot Password Failed";
export const VIEW_MACHINES = "View Machines";
export const VIEW_WORK_ORDERS = "View Work Orders";
export const VIEW_CLOSED_WORK_ORDERS = "View Closed Work Orders";

// Colors
export const MAKULA_DEFAULT_COLOR = "#0517f8";
export const STATUS_BACKGROUND_COLOR_ALPHA = 0.1;

// Machines
export const ITEMS_PER_PAGE = 100;
export const ITEMS_BEFORE_PAGE = 2;

export const MAX_TEXT_DISPLAY_LENGTH = 75;
export const PART_DEFUALT_THUMBNAIL = "/images/default/parts-thumbnail.svg";
export const MACHINE_DETAIL_PARTS_TAB_INDEX = 4;

export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";

export const CHANNELS_LENGTH = 100;

export const THUMBNAIL_DIMENSIONS = "?width=50&height=38&format=png";
export const LARGE_THUMBNAIL_DIMENSIONS = "?width=160&height=120&format=png";

export const ASSIGNEES_AVATARS_MAX_LENGTH = 3;

export const DOCUMENT_FOLDERS = "documentFolders";

export const DATE_FORMAT_DD_MM_YYYY = "DD-MM-YYYY";
export const TIME_FORMAT = "hh:mm A";

export const TEXT_EDITOR_MAX_HEIGHT = 150;
