import * as React from "react";
const SvgRemoveGhost = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 220 220",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#remove-ghost-clip-path)"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M49.66 95.775h-.104l-9.54-.904a1.126 1.126 0 1 1 .21-2.243l9.54.904a1.123 1.123 0 0 1-.105 2.243Zm4.89-10.635a1.119 1.119 0 0 1-.781-.313l-6.897-6.65a1.122 1.122 0 0 1 1.559-1.615l6.896 6.65a1.12 1.12 0 0 1-.777 1.929Zm-5.57 3.492c-.162 0-.323-.036-.47-.105l-8.69-4.033a1.123 1.123 0 1 1 .945-2.037l8.702 4.037a1.12 1.12 0 0 1-.475 2.138h-.011Zm.86 30.853a8.742 8.742 0 0 1-2.99-.531 8.076 8.076 0 0 1-4.71-10.873 8.893 8.893 0 0 1 4.71-4.654l21.306-9.196 1.035 2.4-21.306 9.199a6.28 6.28 0 0 0-3.323 3.286 5.46 5.46 0 0 0 3.185 7.379 6.284 6.284 0 0 0 4.672-.161l19.449-8.399 1.035 2.4-19.467 8.402a9.097 9.097 0 0 1-3.596.748Zm43.024 1.951a8.74 8.74 0 0 1-2.99-.531 8.064 8.064 0 0 1-5.382-7.646c.005-1.111.24-2.21.69-3.227a8.88 8.88 0 0 1 4.654-4.654l21.306-9.2a1.31 1.31 0 0 1 1.734 1.686 1.31 1.31 0 0 1-.698.718l-21.307 9.196a6.291 6.291 0 0 0-3.323 3.285 5.461 5.461 0 0 0 1.263 6.225 5.459 5.459 0 0 0 1.922 1.158 6.29 6.29 0 0 0 4.669-.161l19.478-8.403a1.31 1.31 0 0 1 1.734 1.686c-.129.322-.38.58-.699.718l-19.463 8.399a9.06 9.06 0 0 1-3.588.751Zm43.267-70.804h-2.617a34.015 34.015 0 1 0-68.03 0h-2.617a36.641 36.641 0 1 1 73.283 0h-.019Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M120.861 168.164c-2.404 0-5.502-1.237-8.078-2.523-6.553-3.274-16.563-10.328-27.018-25.336a116.337 116.337 0 0 1-9.76-16.686C65.98 101.468 61.551 76.891 62.852 50.576l2.616.13c-1.286 25.894 3.062 50.06 12.933 71.834.135.295 13.734 29.859 35.563 40.762 4.613 2.303 7.398 2.572 7.899 2.026.717-.781-1.312-4.754-2.404-6.892-2.097-4.112-4.074-7.97-2.093-10.239 2.381-2.725 8.145-.519 15.43 2.643a101.674 101.674 0 0 1 14.821 8.07c2.093 1.327 4.702 2.991 5.282 2.755 0 0 .4-.396 0-2.583a58.504 58.504 0 0 0-1.869-6.448c-1.985-6.074-3.858-11.808-.89-14.066 1.103-.837 2.307-1.166 12.668 2.546a243.25 243.25 0 0 1 7.543 2.852c1.249.49 2.374.934 3.054 1.14-.583-1.918-4.055-6.926-7.431-11.786-2.74-3.955-6.152-8.87-9.681-14.383-.893-1.398-1.783-2.763-2.654-4.112-5.712-8.811-11.105-17.131-14.272-27.153a127.58 127.58 0 0 1-5.842-37.005l2.617-.03a125.05 125.05 0 0 0 5.719 36.258c3.061 9.685 8.118 17.486 13.972 26.517.88 1.351 1.767 2.721 2.662 4.112 3.506 5.468 6.9 10.365 9.632 14.301 6.594 9.502 9.323 13.434 7.349 15.419-1.121 1.121-2.362.617-6.082-.848-1.749-.688-4.141-1.63-7.475-2.823-7.951-2.848-9.794-2.99-10.216-2.911-1.454 1.181.508 7.18 1.809 11.157 1.869 5.723 3.364 10.242.901 11.962-1.869 1.331-4.254-.18-8.194-2.68a98.662 98.662 0 0 0-14.462-7.876c-2.411-1.051-5.413-2.351-7.947-3.08-3.558-1.028-4.347-.374-4.485-.243-.815.931 1.229 4.934 2.452 7.326 2.033 3.974 3.951 7.73 2.003 9.85-.676.781-1.712 1.087-2.919 1.087Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M76.402 73.673a4.733 4.733 0 0 1-4.728-4.725V58.007a4.73 4.73 0 0 1 4.726-4.861 4.729 4.729 0 0 1 4.727 4.861v10.94a4.732 4.732 0 0 1-4.725 4.726Zm0-17.778a2.115 2.115 0 0 0-2.112 2.112v10.94a2.11 2.11 0 0 0 3.635 1.551 2.11 2.11 0 0 0 .585-1.55V58.007a2.112 2.112 0 0 0-2.108-2.112Zm15.165 17.778a4.733 4.733 0 0 1-4.721-4.725V58.007a4.73 4.73 0 1 1 9.453 0v10.94a4.732 4.732 0 0 1-4.732 4.726Zm0-17.778a2.115 2.115 0 0 0-2.112 2.112v10.94a2.11 2.11 0 0 0 3.634 1.551 2.11 2.11 0 0 0 .586-1.55V58.007a2.112 2.112 0 0 0-2.108-2.112Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#CDD1FE",
  d: "M122.723 204.692c31.228 0 56.544-5.352 56.544-11.954s-25.316-11.954-56.544-11.954c-31.229 0-56.544 5.352-56.544 11.954s25.315 11.954 56.544 11.954Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M122.73 206c-15.191 0-29.485-1.256-40.25-3.532-11.677-2.467-17.598-5.742-17.598-9.719 0-3.977 5.92-7.267 17.598-9.719 10.765-2.276 25.044-3.528 40.25-3.528s29.489 1.252 40.254 3.528c11.678 2.467 17.599 5.742 17.599 9.719 0 3.977-5.921 7.263-17.599 9.719-10.772 2.276-25.07 3.532-40.254 3.532Zm0-23.923c-34.22 0-55.232 6.198-55.232 10.646s21.004 10.66 55.232 10.66c34.229 0 55.236-6.201 55.236-10.645 0-4.445-21.018-10.646-55.236-10.646v-.015Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#FDE5F0",
  d: "M163.899 92.899c9.37-9.37 9.37-24.563 0-33.933-9.37-9.37-24.563-9.37-33.933 0-9.37 9.37-9.37 24.562 0 33.933 9.37 9.37 24.563 9.37 33.933 0Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M146.931 101.243a25.305 25.305 0 0 1-23.381-15.627 25.303 25.303 0 0 1 5.489-27.581 25.305 25.305 0 0 1 27.582-5.485 25.31 25.31 0 0 1 15.624 23.383 25.337 25.337 0 0 1-25.314 25.31Zm0-47.987a22.678 22.678 0 1 0 22.681 22.677 22.704 22.704 0 0 0-22.681-22.677Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#EE0064",
  d: "m157.632 73.742-19.127.23a2.258 2.258 0 1 0 .055 4.517l19.126-.232a2.258 2.258 0 0 0-.054-4.515Z"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "remove-ghost-clip-path"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M39 14h141.575v192H39z"
}))));
export default SvgRemoveGhost;
