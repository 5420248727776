import Check from "@shared/svg/check.svg?react";
import ChevronDownIcon from "@shared/svg/chevron-down.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { ErrorMessage, Input, InputLabel } from "@shared/ui/Inputs";
import React, { useState, useMemo, useRef, Fragment, memo } from "react";
import { useIntl } from "react-intl";
import useOnClickOutside from "use-onclickoutside";

import {
  DROPDOWN_POSITIONS,
  DropdownPositionType,
} from "./SingleSelect/SingleSelectDropdown";

type IconDropdownPropsType = {
  className?: string;
  keyId: string;
  label?: string;
  uppercaseLabel?: boolean;
  errorMessage?: string;
  disable?: boolean;
  defaultValue?: any;
  hideSelectedItem?: boolean;
  placeholderClassName?: string;
  placeholder?: string;
  labelField?: string;
  valueField?: string;
  dropdownPosition?: DropdownPositionType;
  vertical?: boolean;
  dropdownTitle?: string;
  showSearchInput?: boolean;
  dropdownListClassName?: string;
  loading?: boolean;
  options?: any[];
  itemRenderer?: (
    option: any,
    onSelectItem: (option: any) => void,
    index: number,
  ) => React.JSX.Element;
  allowToggleSelect?: boolean;
  onChange?: (item: any) => void;
  setSearchQuery?: (query: string) => void;
};

const IconDropdown = ({
  className = "",
  keyId,
  label = "",
  uppercaseLabel = false,
  errorMessage = "",
  disable = false,
  defaultValue = null,
  hideSelectedItem = false,
  placeholderClassName = "",
  placeholder = "",
  labelField = "label",
  valueField = "value",
  dropdownPosition = "bottom",
  vertical = false,
  dropdownTitle = "",
  showSearchInput = false,
  dropdownListClassName = "",
  loading = false,
  options = [],
  itemRenderer = null,
  allowToggleSelect = false,
  onChange = () => {},
  setSearchQuery = () => {},
}: IconDropdownPropsType) => {
  const { messages } = useIntl();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState(defaultValue);
  const [searchValue, setSearchValue] = useState<string>("");
  const dropdownRef = useRef(null);

  const onClickOutside = () => {
    setIsDropdownOpen(false);
    if (searchValue) {
      setSearchValue("");
      setSearchQuery("");
    }
  };

  useOnClickOutside(dropdownRef, onClickOutside);

  // Filtered Dropdown Options
  const dropdownList = useMemo(() => {
    if (searchValue) {
      return options.filter(
        (opt) =>
          opt[labelField]?.toLowerCase().includes(searchValue?.toLowerCase()),
      );
    } else {
      return options;
    }
  }, [options, searchValue, labelField]);

  // On Dropdown Item Click
  const onSelectItem = (item: any) => {
    if (allowToggleSelect && item.value === selectedItem.value) {
      setSelectedItem(null);
      onChange(null);
    } else {
      setSelectedItem(item);
      onChange(item);
    }
    setIsDropdownOpen(false);
    setSearchValue("");
    setSearchQuery("");
  };

  // On Search
  const onSearch = (val: string) => {
    setSearchValue(val);
    setSearchQuery(val);
  };

  // Dropdown Toggler
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <>
      <div className={`relative flex flex-col flex-wrap ${className || ""}`}>
        {label && (
          <div className="w-fit mb-sm">
            <InputLabel
              label={label}
              keyId={keyId}
              uppercaseLabel={uppercaseLabel}
            />
          </div>
        )}
        <div
          className={`${
            disable
              ? "text-disabled bg-accent border-transparent opacity-100"
              : isDropdownOpen
              ? "bg-primary border-brand"
              : defaultValue
              ? "bg-primary border-primary"
              : "border-transparent bg-accent"
          } relative flex items-center justify-between w-full h-4xl py-0 px-md cursor-pointer rounded-lg border border-solid`}
          onClick={() => {
            if (!disable) {
              toggleDropdown();
            }
          }}
        >
          {selectedItem && !hideSelectedItem ? (
            <>
              {selectedItem.icon && (
                <span
                  className={`${
                    selectedItem?.iconClass || ""
                  } flex items-center justify-center w-xl h-xl mr-sm`}
                >
                  {selectedItem.icon}
                </span>
              )}
              <span
                className={`flex-1 text-xs font-medium ${
                  disable ? "text-tertiary" : "text-primary"
                }`}
              >
                {selectedItem[labelField]}
              </span>
            </>
          ) : (
            <span
              className={`text-xs font-medium ${
                disable ? "text-tertiary" : "text-secondary"
              } ${placeholderClassName}`}
            >
              {placeholder}
            </span>
          )}
          <ChevronDownIcon
            width={16}
            height={16}
            className={`cursor-pointer text-secondary ${
              isDropdownOpen ? "rotate-180" : ""
            }`}
          />
        </div>
        {isDropdownOpen && (
          <div
            className={`absolute left-0 right-0 z-[1] w-full py-sm px-0 bg-primary rounded-lg shadow-dropdown ${
              dropdownPosition === DROPDOWN_POSITIONS.TOP
                ? "bottom-[calc(100%-20px)]"
                : "top-[calc(100%+8px)]"
            } ${vertical ? "vertical" : ""}`}
            ref={dropdownRef}
          >
            {dropdownTitle && (
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                color="text-secondary"
                className="px-sm mb-sm z-[1]"
              >
                {dropdownTitle}
              </BodyText>
            )}

            {showSearchInput && (
              <div className="m-sm">
                <Input
                  type="search"
                  placeholder={messages?.tickets?.search}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onSearch(e?.target?.value)
                  }
                  onClick={(
                    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
                  ) => {
                    e.stopPropagation();
                  }}
                />
              </div>
            )}
            <div
              className={`max-h-44 overflow-y-auto ${dropdownListClassName}`}
            >
              {dropdownList.length > 0 ? (
                dropdownList.map((item, index) => {
                  if (itemRenderer) {
                    return (
                      <Fragment key={index}>
                        {itemRenderer(item, onSelectItem, index)}
                      </Fragment>
                    );
                  }
                  return (
                    <Fragment key={index}>
                      <div
                        className="w-full relative flex items-center justify-between cursor-pointer py-sm px-md font-manrope text-xs font-medium text-primary transition-all duration-200 ease-in-out hover:bg-accent"
                        onClick={() => onSelectItem(item)}
                      >
                        <div className="flex items-center">
                          {item.icon && (
                            <span
                              className={`${
                                item?.iconClass || ""
                              } flex items-center justify-center mr-sm w-xl h-xl rounded-sm`}
                            >
                              {item.icon}
                            </span>
                          )}
                          <BodyText
                            size={BODY_TEXT_SIZES.X_SMALL}
                            className="truncate"
                          >
                            {item[labelField]}
                          </BodyText>
                        </div>
                        {selectedItem &&
                          selectedItem?.[valueField] === item?.[valueField] && (
                            <span className="flex items-center justify-center ml-sm">
                              <Check
                                width={16}
                                height={16}
                                className="text-brand"
                              />
                            </span>
                          )}
                      </div>
                      {item.hasBorderBottom && (
                        <div className="h-px w-full bg-tertiary" />
                      )}
                    </Fragment>
                  );
                })
              ) : (
                <>
                  {!loading && (
                    <div className="px-lg py-sm font-manrope text-xs font-medium text-primary">
                      {messages?.common?.noAvailableOptions}
                    </div>
                  )}
                </>
              )}
              {loading && (
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  className="px-lg py-sm"
                >
                  {messages?.common?.loading}
                </BodyText>
              )}
            </div>
          </div>
        )}
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </div>
    </>
  );
};

export default memo(IconDropdown);
