import * as React from "react";
const SvgChevronRight = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_5904_14855)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "m5.6 3 5 5-5 5",
  stroke: "current",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_5904_14855"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M0 0h16v16H0z"
}))));
export default SvgChevronRight;
