import RemoveGhostIcon from "@shared/svg/remove-ghost.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button from "@shared/ui/Button";
import Drawer from "@shared/ui/Drawer";
import { SingleSelectDropdown } from "@shared/ui/Dropdowns";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { Input, Counter } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import AppModal from "@shared/ui/Modal";
import TextEditor, { serialize } from "@shared/ui/TextEditor";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { CaretLeft, MagnifyingGlass, Plus, Trash } from "phosphor-react";
import React, { useMemo, useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

import { OpenTicketsAtom } from "~/atoms/_openTicket";
import EmptySpace from "~/components/_emptySpace";
import { NoTicketsLarge } from "~/components/general/_no-tickets";
import useAuth from "~/components/general/_use-auth";
import IconDropdown from "~/components/IconDropdown";
import AddedPartToWorkOrder from "~/components/parts/_addedPartToWorkOrder";
import { CreateWorkOrderPartRow } from "~/components/parts/_part-raw";
import WorkOrderFormCustomElements from "~/components/WorkOrderFormCustomElements";
import WorkOrderTypeIcons from "~/components/WorkOrderTypeIcons";
import { APP_FEATURES_ENUM } from "~/constants";
import { ITEMS_PER_PAGE } from "~/constants/global";
import { NoUserAssignedIcon } from "~/icons";
import { createTicket, useGetWorkOrderTypes } from "~/services";
import { useGetAppConfig } from "~/services/appConfig/query";
import { useGetAssetsByParams } from "~/services/asset";
import { getMachinesByParams } from "~/services/machine/_query";
import {
  generateStatusBackgroundColor,
  getPartThumbnail,
  getMachineThumbnail,
} from "~/utils";

const Create = ({ buttonComponent }) => {
  const { messages } = useIntl();
  const currentUser = useAuth() || {};

  const { appConfig } = useGetAppConfig();

  const isHierarchyEnabled = appConfig?.features?.includes(
    APP_FEATURES_ENUM.hierarchy,
  );

  const assetTypeOptions = useMemo(
    () =>
      currentUser?.user?.oem?.assetTypes?.map((type) => ({
        label: type.name,
        value: type._id,
      })) || [],
    [currentUser?.user?.oem?.assetTypes],
  );

  const workOrderTypes = useGetWorkOrderTypes();
  const [openTicketCoil, setOpenTicketCoil] = useRecoilState(OpenTicketsAtom);
  const {
    isPartsRequest = false,
    defaultSelectedMachine = null,
    description: ticketDescription = "",
  } = openTicketCoil;
  const [addPartsModal, setAddPartsModal] = useState(false);
  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [newTicket, setNewTicket] = useState({
    selectedMachine: null,
    machineError: "",
    title: "",
    titleError: "",
    description: "",
    descriptionError: "",
    ticketType: null,
    ticketTypeError: "",
  });
  const { mutation: createNewTicket } = createTicket();
  const errorMessages = messages?.errors;
  const success = messages?.tickets?.create?.success;
  const [partSearchTerm, setPartSearchTerm] = useState("");
  const [addedPartsToTicket, setAddedPartsToTicket] = useState([]);
  const [addedParts, setAddedParts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [detailPart, setDetailPart] = useState(null);
  const [detailPartUpdate, setDetailPartUpdate] = useState(null);
  const [selectedWorkOrderType, setSelectedWorkType] = useState(null);
  const [customFieldsData, setCustomFieldsData] = useState({});
  const [customFieldsErrors, setCustomFieldsErrors] = useState({});

  const [showPartRemoveConfirmation, setShowPartRemoveConfirmation] =
    useState(false);
  const tempRemovePartId = useRef(null);
  const isExternalChange = useRef(false);

  const {
    assets,
    totalCount: assetsTotalCount,
    loading: assetsLoading,
    handleFetchMore: handleFetchMoreAssets,
  } = useGetAssetsByParams({
    where: {
      ...(selectedAssetType ? { assetTypes: [selectedAssetType?.value] } : {}),
      searchQuery,
    },
    skipCondition: !isHierarchyEnabled,
  });
  const { machines, totalCount, loading, handleFetchMore } =
    getMachinesByParams({
      where: {
        searchQuery,
        includeProductionLineMachines: true,
      },
      skipCondition: isHierarchyEnabled,
    });

  const handleScrollBottom = (event) => {
    if (
      (isHierarchyEnabled ? assets : machines).length >= ITEMS_PER_PAGE &&
      !(isHierarchyEnabled ? assetsLoading : loading) &&
      event?.previousPosition !== "above" &&
      (isHierarchyEnabled
        ? assetsTotalCount > assets.length
        : totalCount > machines.length)
    ) {
      (isHierarchyEnabled ? handleFetchMoreAssets : handleFetchMore)({
        limit: ITEMS_PER_PAGE,
        skip: (isHierarchyEnabled ? assets : machines).length,
      });
    }
  };

  const resetCustomFieldsData = () => {
    setCustomFieldsData({});
    setCustomFieldsErrors({});
  };

  useEffect(() => {
    if (defaultSelectedMachine) {
      setNewTicket((prev) => ({
        ...prev,
        selectedMachine: {
          ...defaultSelectedMachine,
          label:
            defaultSelectedMachine?.name +
            " • " +
            defaultSelectedMachine?.serialNumber,
          value: defaultSelectedMachine?._id,
        },
      }));

      isExternalChange.current = true;
      setSelectedAssetType(
        assetTypeOptions?.find(
          (type) => type.value === defaultSelectedMachine?.assetType,
        ) ?? null,
      );
      setTimeout(() => {
        isExternalChange.current = false;
      }, 0);
    }
  }, [assetTypeOptions, defaultSelectedMachine]);

  useEffect(() => {
    if (ticketDescription)
      setNewTicket((prev) => ({
        ...prev,
        description: ticketDescription,
      }));
  }, [ticketDescription]);

  const handleCloseAddPartsModal = () => {
    setAddPartsModal(false);
    setAddedParts([]);
    setDetailPartUpdate(null);
    setPartSearchTerm("");
  };

  const addParts = () => {
    if (addedParts.length) {
      setAddedPartsToTicket((prevParts) => [...prevParts, ...addedParts]);
    }
    handleCloseAddPartsModal();
  };

  const getPartCount = (currentPart) => {
    const part = addedParts.find(
      (addedPart) => addedPart._id === currentPart._id,
    );
    return part?.quantity ?? 0;
  };

  const addPartToCard = (
    newPart,
    isAdd = true,
    isRemove = false,
    isInputValue = false,
    quantity = 0,
  ) => {
    const addedPartsId = addedParts?.map((item) => item._id);
    if (addedPartsId.includes(newPart._id)) {
      if (isAdd) {
        if (isInputValue) {
          setAddedParts((prevParts) => {
            return prevParts.map((prevItem) =>
              prevItem._id === newPart._id
                ? { ...prevItem, quantity }
                : prevItem,
            );
          });
          return;
        }
        setAddedParts((prevParts) => {
          return prevParts.map((prevItem) =>
            prevItem._id === newPart._id
              ? { ...prevItem, quantity: prevItem.quantity + 1 }
              : prevItem,
          );
        });
      } else {
        const findPart = addedParts.find((item) => item._id === newPart._id);
        if (isRemove) {
          setAddedParts((prevParts) => {
            return prevParts.filter((prevItem) => prevItem._id !== newPart._id);
          });
        } else if (findPart.quantity > 1) {
          setAddedParts((prevParts) => {
            return prevParts.map((prevItem) =>
              prevItem._id === newPart._id
                ? { ...prevItem, quantity: prevItem.quantity - 1 }
                : prevItem,
            );
          });
        } else {
          setAddedParts((prevParts) => {
            return prevParts.filter((prevItem) => prevItem._id !== newPart._id);
          });
        }
      }
    } else {
      if (isInputValue) {
        // adding part through input value only if its quantity is entered
        quantity > 0 &&
          setAddedParts((prevParts) => [
            ...prevParts,
            { ...newPart, quantity },
          ]);
        return;
      }
      setAddedParts((prevParts) => [...prevParts, { ...newPart, quantity: 1 }]);
    }
  };

  // Filtered Parts or All Parts
  const filteredMchineParts = useMemo(() => {
    const alreadAddedPartsId = addedPartsToTicket.map((item) => item._id);
    const filteredParts =
      newTicket?.selectedMachine?.inventoryParts?.filter(
        (part) => !alreadAddedPartsId.includes(part.part._id),
      ) || [];
    return partSearchTerm
      ? filteredParts?.filter((part) => {
          return (
            part.part.name
              .toLowerCase()
              .includes(partSearchTerm.toLowerCase()) ||
            part.part.articleNumber
              .toLowerCase()
              .includes(partSearchTerm.toLowerCase())
          );
        })
      : filteredParts;
  }, [partSearchTerm, newTicket?.selectedMachine, addedPartsToTicket]);

  const machineOptions = (isHierarchyEnabled ? assets : machines)
    ?.filter((m) => m?._id !== newTicket?.selectedMachine?._id)
    ?.map((m) => {
      return {
        ...m,
        label: m?.name,
        value: m?._id,
        description: m?.serialNumber,
        thumbnail: getMachineThumbnail(m),
      };
    });

  const handleDrawerClose = () => {
    resetStates();
  };

  const resetStates = () => {
    setOpenTicketCoil({
      ...openTicketCoil,
      isDrawerOpen: false,
      isPartsRequest: false,
      defaultSelectedMachine: null,
      description: "",
    });
    setNewTicket({
      selectedMachine: null,
      machineError: "",
      title: "",
      titleError: "",
      description: "",
      descriptionError: "",
      ticketType: null,
      ticketTypeError: "",
    });
    setAddedPartsToTicket([]);
    setAddedParts([]);
    setSearchQuery("");
    setSelectedWorkType(null);
    resetCustomFieldsData();
    setSelectedAssetType(null);
  };

  const TICKET_TYPE_OPTIONS = workOrderTypes.map((type) => ({
    label: type.name,
    itemId: type._id,
    value: type._id,
    icon: (
      <div
        className="work-order-type-icon"
        style={{ backgroundColor: generateStatusBackgroundColor(type.color) }}
      >
        <WorkOrderTypeIcons
          icon={type.icon}
          strokeColor={type.color}
          size={12}
        />
      </div>
    ),
  }));

  const handleCreateTicketSubmit = async () => {
    try {
      const newData = {
        machineId: newTicket.selectedMachine?._id,
        title: newTicket.title,
        ticketType: newTicket.ticketType?.value,
        description: selectedWorkOrderType?.descriptionConfig?.show
          ? newTicket.description
          : "",
      };

      if (selectedWorkOrderType?.partsConfig?.show) {
        newData.inventoryParts = addedPartsToTicket.map((item) => {
          return {
            part: item._id,
            addedBy: currentUser?.user?._id,
            quantity: item.quantity,
          };
        });
      }

      if (Array.isArray(selectedWorkOrderType?.customFields)) {
        for (const customField of selectedWorkOrderType.customFields) {
          if (
            customFieldsData[customField.customAdditionalField._id] !==
            undefined
          ) {
            newData.customFields = newData.customFields || [];
            newData.customFields.push({
              _id: customField._id,
              fieldId: customField.customAdditionalField._id,
              value: customFieldsData[customField.customAdditionalField._id],
            });
          }
        }
      }

      await createNewTicket(newData);

      resetStates();
      toast(<Toast message={success?.message} />, { closeButton: false });
    } catch (e) {
      toast(
        <Toast
          message={e?.message || errorMessages?.common}
          type={TOAST_TYPES.ERROR}
        />,
        {
          closeButton: false,
        },
      );
    }
  };

  const isAllCustomRequiredFilled = () => {
    if (!selectedWorkOrderType) return false;

    // check if custom work order type
    if (
      selectedWorkOrderType.partsConfig?.show &&
      selectedWorkOrderType.partsConfig?.isRequired &&
      !Object.keys(addedPartsToTicket).length
    ) {
      return false;
    }

    // description field is required if work order type is default
    // or configured as required for custom work order types
    if (
      selectedWorkOrderType.descriptionConfig?.show &&
      selectedWorkOrderType.descriptionConfig?.isRequired &&
      (newTicket.description === "" || !serialize(newTicket.description).length)
    ) {
      return false;
    }

    for (const field of selectedWorkOrderType.customFields) {
      if (
        field.isRequired &&
        !customFieldsData[field.customAdditionalField._id]
      ) {
        return false;
      }
    }

    return true;
  };

  const shouldDisableSubmit = () => {
    const allCustomFieldFilled = isAllCustomRequiredFilled();

    return (
      !newTicket.selectedMachine ||
      !newTicket.title ||
      !newTicket.ticketType ||
      !allCustomFieldFilled
    );
  };

  const onPartDeleteAccept = () => {
    setAddedPartsToTicket((prevParts) =>
      prevParts.filter((item) => item._id !== tempRemovePartId.current),
    );
    setShowPartRemoveConfirmation(false);
  };

  const onClickButton = () =>
    setOpenTicketCoil({ ...openTicketCoil, isDrawerOpen: true });

  const incrementCount = () => {
    setDetailPart({ ...detailPart, countValue: detailPart?.countValue + 1 });
  };

  const decrementCount = () => {
    setDetailPart({ ...detailPart, countValue: detailPart?.countValue - 1 });
  };

  const onBack = () => {
    setDetailPartUpdate(detailPart);
    setDetailPart(null);
  };

  const incrementaddedPartToTicketCount = (part) => {
    let index = addedPartsToTicket.findIndex((item) => item._id === part._id);
    let updatedData = addedPartsToTicket;
    if (index >= 0) {
      updatedData[index] = {
        ...part,
        quantity: part.quantity + 1,
      };
    } else return;

    setAddedPartsToTicket([...updatedData]);
  };

  const decrementaddedPartToTicketCount = (part) => {
    if (part.quantity === 1) {
      setAddedPartsToTicket((prevParts) =>
        prevParts.filter((item) => item._id !== part._id),
      );
    } else {
      let index = addedPartsToTicket.findIndex((item) => item._id === part._id);
      let updatedData = addedPartsToTicket;
      if (index >= 0) {
        updatedData[index] = {
          ...part,
          quantity: part.quantity - 1,
        };
      } else return;

      setAddedPartsToTicket([...updatedData]);
    }
  };

  return (
    <>
      {buttonComponent ? (
        buttonComponent(onClickButton)
      ) : (
        <Button
          text={messages?.header?.ticketListing?.action}
          className="ml-sm"
          onClick={onClickButton}
        />
      )}
      <Drawer
        title={
          isPartsRequest
            ? messages?.parts?.partsRequest
            : messages?.header?.ticketListing?.workOrderCreateTitle
        }
        isOpen={openTicketCoil?.isDrawerOpen}
        onClose={handleDrawerClose}
        onSubmit={handleCreateTicketSubmit}
        submitButtonDisabled={shouldDisableSubmit()}
        submitButtonText={messages?.tickets?.newTicketForm?.submit}
      >
        <div className="add-new-ticket-content">
          <IconDropdown
            options={TICKET_TYPE_OPTIONS}
            className={`work-types-dropdown ${
              newTicket.ticketTypeError ? "u-margin-b-0" : ""
            }`}
            onChange={(item) => {
              const workOrderType = currentUser?.user?.oem?.ticketTypes?.find(
                (type) => type._id === item.value,
              );

              setSelectedWorkType(workOrderType || null);
              setNewTicket({
                ...newTicket,
                ticketType: item,
                ticketTypeError: "",
              });
            }}
            defaultValue={newTicket.ticketType}
            label={messages?.tickets?.newTicketForm?.type?.label + " *"}
            placeholder={messages?.tickets?.newTicketForm?.type?.label}
            errorMessage={newTicket.ticketTypeError}
            placeholderClassName="u-text-color-gray-v2-60"
          />

          {isHierarchyEnabled && (
            <SingleSelectDropdown
              keyId="asset-type"
              uppercaseLabel={false}
              label={messages["hierarchy"]["assetTypes"]["dropDown"]["label"]}
              placeholder={
                messages["hierarchy"]["assetTypes"]["dropDown"]["placeholder"]
              }
              disabled={defaultSelectedMachine}
              options={assetTypeOptions}
              onChange={(selected) => {
                if (isExternalChange.current) return;

                setSelectedAssetType(selected?.[0]);

                setNewTicket({
                  ...newTicket,
                  selectedMachine: [],
                });
                setAddedPartsToTicket([]);
              }}
              searchable
              // @ts-ignore
              searchBy="label"
              values={selectedAssetType ? [selectedAssetType] : []}
            />
          )}

          {defaultSelectedMachine ? (
            <SingleSelectDropdown
              keyId="selected-machine"
              disabled
              label={
                isHierarchyEnabled
                  ? messages["hierarchy"]["assets"]["dropDown"]["label"]
                  : messages?.tickets?.newTicketForm.machine.label
              }
              placeholder={
                isHierarchyEnabled
                  ? messages["hierarchy"]["assets"]["dropDown"]["placeholder"]
                  : messages?.tickets?.newTicketForm.machine.placeholder
              }
              values={
                [
                  {
                    ...defaultSelectedMachine,
                    label:
                      defaultSelectedMachine?.name +
                      " • " +
                      defaultSelectedMachine?.serialNumber,
                    value: defaultSelectedMachine?._id,
                  },
                ] || []
              }
            />
          ) : (
            <SingleSelectDropdown
              keyId="select-machine"
              searchable
              label={`${
                isHierarchyEnabled
                  ? messages["hierarchy"]["assets"]["dropDown"]["label"]
                  : messages?.tickets?.newTicketForm?.machine?.label
              } *`}
              placeholder={
                isHierarchyEnabled
                  ? messages["hierarchy"]["assets"]["dropDown"]["placeholder"]
                  : messages?.tickets?.newTicketForm?.machine?.placeholder
              }
              options={machineOptions}
              onChange={(selected) => {
                if (isExternalChange.current || !selected.length) return;

                const assetType = assetTypeOptions?.find(
                  (type) => type.value === selected?.[0]?.assetType,
                );
                isExternalChange.current = true;
                setSelectedAssetType(assetType || null);

                setNewTicket({
                  ...newTicket,
                  selectedMachine: selected?.[0],
                });
                setAddedPartsToTicket([]);

                setTimeout(() => {
                  isExternalChange.current = false;
                }, 0);
              }}
              isAsync={true}
              asyncProps={{
                isLoading: isHierarchyEnabled ? assetsLoading : loading,
                onScrollToBottom: handleScrollBottom,
                onSearch: setSearchQuery,
              }}
              errorMessage={newTicket.machineError}
              values={
                newTicket.selectedMachine?._id
                  ? [newTicket.selectedMachine]
                  : []
              }
              onDropdownOpen={() => {}}
            />
          )}

          <Input
            label={messages?.tickets?.newTicketForm.title.label}
            keyId="add-ticket-title"
            placeholder={messages?.tickets?.newTicketForm.title.placeholder}
            value={newTicket.title}
            errorMessage={newTicket.titleError}
            onChange={(e) => {
              const titleLength = e.target.value.length;

              if (titleLength > 75) {
                return setNewTicket({
                  ...newTicket,
                  title: e.target.value.slice(0, 75),
                  titleError:
                    messages?.tickets?.newTicketForm.title.lengthError,
                });
              } else {
                return setNewTicket({
                  ...newTicket,
                  title: e.target.value,
                  titleError: "",
                });
              }
            }}
          />

          {selectedWorkOrderType?.descriptionConfig?.show ? (
            <>
              {/* description field is required for default work order types */}
              <TextEditor
                keyId="work-order-description"
                label={`${
                  selectedWorkOrderType?.descriptionConfig?.fieldName ||
                  messages?.tickets?.newTicketForm.description.label
                } ${
                  selectedWorkOrderType?.descriptionConfig?.isRequired
                    ? "*"
                    : ""
                }`}
                content={newTicket.description}
                placeholder={
                  messages?.tickets?.newTicketForm.description.placeholder
                }
                autoFocus={false}
                onUpdate={(newValue) => {
                  setNewTicket({
                    ...newTicket,
                    description: JSON.stringify(newValue),
                  });
                }}
              />
            </>
          ) : null}

          {selectedWorkOrderType?.partsConfig?.show && (
            <div className="u-flex u-flex-column u-width-100">
              <div className="u-width-100 u-flex u-justify-between">
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="no-styles"
                >
                  {selectedWorkOrderType?.partsConfig?.fieldName ||
                    messages.parts.parts}{" "}
                  {/* parts field is optional for default work order types */}
                  {selectedWorkOrderType?.partsConfig?.isRequired ? "*" : ""}
                </BodyText>
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="no-styles"
                >
                  <span className="text-brand">
                    {addedPartsToTicket.length}
                  </span>
                  {` ${messages.parts.partsAdded}`}
                </BodyText>
              </div>
              <div className="mk-accordion__bottom">
                <button
                  className="create-work-order-add-parts-cta"
                  disabled={!newTicket?.selectedMachine?._id}
                  onClick={(e) => {
                    e.preventDefault();
                    if (!newTicket?.selectedMachine._id) return;
                    setAddPartsModal(true);
                  }}
                >
                  <Plus size={12} weight="fill" />
                  <Label
                    size={LABEL_SIZES.SMALL}
                    color={
                      newTicket?.selectedMachine?._id
                        ? "text-brand"
                        : "text-tertiary"
                    }
                  >
                    {messages?.parts?.addParts}
                  </Label>
                </button>
              </div>
              <div>
                {addedPartsToTicket.map((part) => {
                  return (
                    <AddedPartToWorkOrder
                      key={part._id}
                      part={part}
                      machine={newTicket.selectedMachine ?? {}}
                      incrementCount={() =>
                        incrementaddedPartToTicketCount(part)
                      }
                      decrementCount={() =>
                        decrementaddedPartToTicketCount(part)
                      }
                      onDeleteClick={() => {
                        setShowPartRemoveConfirmation(true);
                        tempRemovePartId.current = part._id;
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}

          {/* custom configured form elements */}
          <WorkOrderFormCustomElements
            fields={selectedWorkOrderType?.customFields || []}
            formData={customFieldsData}
            errorData={customFieldsErrors}
            onChange={setCustomFieldsData}
            updateErrorData={setCustomFieldsErrors}
          />
        </div>
      </Drawer>
      <AppModal
        isOpen={addPartsModal}
        overlayClasses="!z-20"
        hideUpdateButton={!filteredMchineParts?.length}
        disableUpdate={!addedParts.length}
        title={detailPart ? null : messages?.tickets?.addParts}
        header={
          detailPart ? (
            <div className="flex items-center py-2xl px-2xl border-solid border-b border-t-0 border-x-0 border-primary">
              <div
                className="spare-part-order0detail-back-button"
                onClick={onBack}
              >
                <CaretLeft size={16} />
              </div>

              <Headline size={HEADLINE_SIZES.X_SMALL}>
                {messages.parts.partDetails}
              </Headline>
            </div>
          ) : null
        }
        handleSubmit={addParts}
        handleClose={handleCloseAddPartsModal}
        className="equal-column-modal spare-part-order-global-modal"
        contentClassname={`spare-part-order-global-modal-content ${
          detailPart ? "create-spare-part-order-modal-content" : ""
        }
              `}
        footer={
          detailPart ? (
            <>
              <div className="flex items-center justify-center py-lg px-2xl border-solid border-t border-b-0 border-x-0 border-primary">
                {detailPart.countValue > 0 && (
                  <div
                    className="create-spare-part-order-modal-part-remove-button"
                    onClick={() =>
                      setDetailPart({ ...detailPart, countValue: 0 })
                    }
                  >
                    <Trash size={16} />
                  </div>
                )}
                <Counter
                  count={detailPart?.countValue ?? 0}
                  setCount={(val) =>
                    setDetailPart({ ...detailPart, countValue: val })
                  }
                  incrementCount={incrementCount}
                  decrementCount={decrementCount}
                  isReadOnlyInput={false}
                />
              </div>
            </>
          ) : null
        }
        content={
          detailPart ? (
            <>
              <div className="u-flex u-flex-column create-spare-part-order-detail-modal">
                <img
                  className="part-thumbnail"
                  src={getPartThumbnail(detailPart)}
                />
                <div className="part-detail-wrapper u-flex u-flex-column">
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    color="text-secondary"
                  >
                    {messages.parts.partName + ":"}
                  </BodyText>
                  <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                    {detailPart.name}
                  </BodyText>
                </div>
                <div className="divider" />
                <div className="part-detail-wrapper u-flex u-flex-column">
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    color="text-secondary"
                  >
                    {messages.parts.description + ":"}
                  </BodyText>
                  <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                    {detailPart.description || "-"}
                  </BodyText>
                </div>
              </div>
            </>
          ) : (
            <>
              <Input
                leadingIconConfig={{ icon: <MagnifyingGlass size={16} /> }}
                className="pl-9"
                placeholder={messages?.common?.search}
                value={partSearchTerm}
                onChange={(e) => {
                  setPartSearchTerm(e.target.value);
                }}
              />
              <EmptySpace height="12px" />
              {filteredMchineParts?.length ? (
                <>
                  {filteredMchineParts.map((part) => {
                    return (
                      <CreateWorkOrderPartRow
                        key={part.part._id}
                        part={part.part}
                        addPartToCard={addPartToCard}
                        onShowDetails={(part) => {
                          setDetailPart(part);
                          setDetailPartUpdate(null);
                        }}
                        detailPart={detailPartUpdate}
                        defaultCount={() => getPartCount(part.part)}
                      />
                    );
                  })}
                </>
              ) : (
                <NoTicketsLarge
                  icon={<NoUserAssignedIcon />}
                  title={
                    messages?.parts[
                      isHierarchyEnabled ? "noPartsFoundAsset" : "noPartsFound"
                    ]
                  }
                />
              )}
            </>
          )
        }
        updateButtonText={
          <>
            {addedParts.length > 0 && (
              <BodyText
                className="flex items-center justify-center w-2xl h-2xl rounded-md bg-brand-medium"
                size={BODY_TEXT_SIZES.X_SMALL}
                color="text-inverse"
              >
                {addedParts.length}
              </BodyText>
            )}
            {messages.parts.addSelectedParts}
          </>
        }
      />
      <AlertBox
        title={messages?.tickets?.partRemoveAlertTitle}
        isOpen={showPartRemoveConfirmation}
        zIndexClass="z-30"
        acceptButtonText={messages?.common?.confirmRemoveText}
        cancelButtonText={messages?.common?.deleteCancelButtonText}
        image={<RemoveGhostIcon width="130" height="130" />}
        onAccept={onPartDeleteAccept}
        onCancel={() => {
          setShowPartRemoveConfirmation(false);
          tempRemovePartId.current = null;
        }}
        overlay={false}
      />
    </>
  );
};
export default Create;
