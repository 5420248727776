import AppHeader from "@shared/ui/AppHeader";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { SearchInput } from "@shared/ui/Inputs";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import PAID_FEATURES from "$/settings/paid-features.json";
import { useAuth } from "~/components/general";
import Hamburger from "~/components/header/Hamburger";
import MobileSearch from "~/components/header/MobileSearch";
import UnderlineTabsWrapper from "~/components/UnderlineTabsWrapper";
import { APP_FEATURES_ENUM } from "~/constants";
import MachinesListing from "~/containers/_machinesListing.jsx";
import ProductionLinesListing from "~/containers/_productionLineslisting";
import { getMachinesByParams } from "~/services";
import { useGetAppConfig } from "~/services/appConfig/query";
import { useGetAssetsByParams } from "~/services/asset";
import { getProuctionLines } from "~/services/productionLine";
import { getEnums } from "~/utils";

const paidFeaturesRef = getEnums(PAID_FEATURES, "reference");

const TABS = {
  PRODUCTION_LINES: "production-lines",
  MACHINES: "machines",
};

const Assets = ({ intl }) => {
  const { messages } = useIntl();
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(TABS.PRODUCTION_LINES);
  const history = useHistory();
  const { user } = useAuth();

  const isProductionLinePaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef.productionLines,
  );

  const { appConfig } = useGetAppConfig();

  const isHierarchyEnabled = appConfig?.features?.includes(
    APP_FEATURES_ENUM.hierarchy,
  );

  const isProductionLinesTabActive = isProductionLinePaid && activeTab === 0;

  const {
    assets,
    totalCount: assetsTotalCount,
    loading: assetsLoading,
    handleFetchMore: assetsHandleFetchMore,
  } = useGetAssetsByParams({
    where: {
      searchQuery: searchValue,
      ...(searchValue ? {} : { assetAssigned: false }),
    },
    skipCondition: !isHierarchyEnabled,
  });

  const {
    machines = [],
    loading,
    totalCount,
    handleFetchMore,
  } = getMachinesByParams({
    where: {
      searchQuery: isProductionLinesTabActive ? "" : searchValue,
    },
    skipCondition: isHierarchyEnabled,
  });

  const {
    productionLines,
    loading: productionLinesLoading,
    totalCount: productionLinesTotalCount,
    handleFetchMore: handleFetchMoreProductionLines,
  } = getProuctionLines({
    where: {
      searchQuery: isProductionLinesTabActive ? searchValue : "",
    },
    skipCondition: isHierarchyEnabled,
  });

  useEffect(() => {
    if (isHierarchyEnabled) return;

    const path = history.location.pathname;
    if (isProductionLinePaid) {
      if (path.endsWith("production-lines")) {
        setActiveTab(TABS.PRODUCTION_LINES);
      } else if (path.endsWith("machines")) {
        setActiveTab(TABS.MACHINES);
      } else if (path.endsWith("assets")) {
        setActiveTab(TABS.PRODUCTION_LINES);
        history.replace(`/${intl?.locale}/app/assets/production-lines`);
      }
    } else if (path.endsWith("assets") || path.endsWith("production-lines")) {
      history.replace(`/${intl?.locale}/app/assets/machines`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, isHierarchyEnabled]);

  const onSearch = (value) => setSearchValue(value);

  return (
    <>
      <AppHeader
        padding="py-xl lg:py-lg"
        className="mx-xl md:mx-2xl max-lg:mb-sm"
      >
        <Headline size={HEADLINE_SIZES.SMALL}>
          {messages?.header.machines.title}
        </Headline>
        <div className="flex items-center desktop-element">
          <SearchInput
            onChange={onSearch}
            value={searchValue}
            placeholder={
              !isHierarchyEnabled && isProductionLinesTabActive
                ? messages?.productionLines.searchPlaceHolder
                : messages?.machines?.searchPlaceholder
            }
          />
        </div>
        {/* Hamburger component is only for mobile view */}
        <Hamburger />
      </AppHeader>
      {/* MobileSearch component is only for mobile view */}
      <MobileSearch onChange={onSearch} value={searchValue} />
      {isHierarchyEnabled ? (
        <div className="mt-lg px-2xl">
          <MachinesListing
            intl={intl}
            machines={assets}
            loading={assetsLoading}
            totalCount={assetsTotalCount}
            handleFetchMore={assetsHandleFetchMore}
            isAsset
            searchValue={searchValue}
          />
        </div>
      ) : (
        <div className="assets-tabs-wrapper u-width-100">
          <div className="page-details-tab">
            <UnderlineTabsWrapper
              currentTab={activeTab}
              tabs={[
                isProductionLinePaid && {
                  id: TABS.PRODUCTION_LINES,
                  label: `${messages?.productionLines?.title} (${
                    productionLinesTotalCount ?? 0
                  })`,
                  path: `/${intl?.locale}/app/assets/production-lines`,
                  content: (
                    <ProductionLinesListing
                      hasProductionLines={Boolean(productionLines?.length)}
                      productionLines={productionLines}
                      intl={intl}
                      loading={productionLinesLoading}
                      totalCount={productionLinesTotalCount}
                      handleFetchMore={handleFetchMoreProductionLines}
                    />
                  ),
                },
                {
                  id: TABS.MACHINES,
                  label: `${messages?.machines?.title} (${totalCount ?? 0})`,
                  path: `/${intl?.locale}/app/assets/machines`,
                  content: (
                    <MachinesListing
                      intl={intl}
                      machines={machines}
                      loading={loading}
                      totalCount={totalCount}
                      handleFetchMore={handleFetchMore}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Assets;
