import * as React from "react";
const SvgPart = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 16 16",
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.2,
  clipPath: "url(#part)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M13.75 10.956V5.044a.506.506 0 0 0-.256-.438l-5.25-2.968a.487.487 0 0 0-.488 0l-5.25 2.968a.506.506 0 0 0-.256.438v5.912a.506.506 0 0 0 .256.438l5.25 2.969a.487.487 0 0 0 .488 0l5.25-2.97a.506.506 0 0 0 .256-.437v0Z"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8 10.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
})));
export default SvgPart;
