import Avatar, { AVATAR_SIZES, AvatarSizeType } from "./Avatar";

type AvatarGroupPropsType = {
  size?: AvatarSizeType;
  showTooltip?: boolean;
  names?: string[];
};

const AvatarGroup = ({
  size = AVATAR_SIZES.SMALL,
  showTooltip = true,
  names = [],
}: AvatarGroupPropsType) => {
  return (
    <>
      {names.map((name, index) => (
        <Avatar
          key={`${name}-${index}`}
          name={name}
          size={size}
          showTooltip={showTooltip}
          containerClassName={index === 0 ? "ml-0" : "ml-[-5px]"}
          style={{ zIndex: names.length - index }}
          className="border border-solid border-inverse box-content"
        />
      ))}
    </>
  );
};

export default AvatarGroup;
