import LogRocket from "logrocket";
import mixpanel from "mixpanel-browser";
import React, { useState, useEffect, Suspense } from "react";
import { injectIntl } from "react-intl";
import { useRecoilState } from "recoil";

import { imagePreviewAtom } from "~/atoms/_chat";
import { LoginAlert } from "~/components/_alert";
import ImagePreview from "~/components/_imagePreview";
import Navigation from "~/components/_navigation";
import { Loading } from "~/components/general";
import useAuth from "~/components/general/_use-auth";
import Main from "~/layout/_main";
import Routes from "~/routes/index";
import { useGetAppConfig } from "~/services/appConfig/query";
import { isLogRocketEnabled } from "~/utils/_logRocket";
import { isMixpanelEnabled } from "~/utils/_mixpanel";

let userIdentified;

const Layout = (props) => {
  const { user = {}, loading } = useAuth() || {};
  const [routes, setRoutes] = useState([]);
  const [imagePreviewOptions, setImagePreview] =
    useRecoilState(imagePreviewAtom);

  const { loading: appConfigLoading, appConfig } = useGetAppConfig();

  const closeImagePreview = () => {
    setImagePreview({
      ...imagePreviewOptions,
      isOpen: false,
    });
  };

  useEffect(() => {
    if (isLogRocketEnabled()) {
      if (user?._id && !userIdentified) {
        LogRocket.identify(user?._id, {
          name: user?.name,
          email: user?.email,
          role: user?.role,
        });
        userIdentified = true;
      }
    }
  }, [user]);

  useEffect(() => {
    if (!isMixpanelEnabled() || !user?._id) return;
    mixpanel.identify(user?._id);
    mixpanel.people.set({
      $name: user?.name,
      $email: user?.email,
      role: user?.role,
      oem: user?.oem?.slug,
      facilityName: user?.facility?.name,
    });
  }, [user]);

  useEffect(() => {
    if (!routes.length && Object.keys(user || {}).length)
      setRoutes(Routes(user));
  }, [user, loading]);

  if (!routes.length || appConfigLoading) return <Loading />;

  // [NOTE]: this is for machine single view (no sidebar or navigation)
  /// ///////// BEGIN /////////////////
  const MACHINE_SINGLE_QRC_VIEW = false; // put this in app settings

  if (MACHINE_SINGLE_QRC_VIEW) {
    const { location } = props;
    if (location) {
      const path = "/machines/view/";
      if (location.pathname?.includes(path)) {
        const machinesViewRoute = routes.find((r) => r.path?.includes(path));
        if (machinesViewRoute) {
          const machineUuid =
            location.pathname.split("/")[
              location.pathname.split("/").length - 1
            ];
          return machinesViewRoute.Main({
            ...props,
            machineUuid,
          });
        }
      }
    }
  }

  /// ///////// END ////////////////////

  return (
    <>
      <ImagePreview
        intl={props?.intl}
        closeModal={closeImagePreview}
        openedImage={imagePreviewOptions?.openedImage}
        isModalOpen={imagePreviewOptions?.isOpen}
      />
      <Navigation {...props} isOpen={true} routes={routes} />
      <Suspense fallback={<Loading />}>
        <Main
          {...props}
          routes={routes}
          DefaultRoute={routes[0]}
          user={user}
          appConfig={appConfig}
        />
      </Suspense>
      <LoginAlert />
    </>
  );
};

export default injectIntl(Layout);
