import { useIntl } from "react-intl";

import DropdownItem from "../DropdownItem";

type SingleSelectItemProps = {
  props: any;
  state: any;
  methods: any;
  showAvatar?: boolean;
  customOptionItem?: (option: any) => React.ReactNode;
};

const SingleSelectItem = ({
  props,
  state,
  methods,
  showAvatar = false,
  customOptionItem = null,
}: SingleSelectItemProps) => {
  const { messages } = useIntl();

  const regexp = new RegExp(state.search, "i");
  const searchBy = props.searchBy?.replace(" ", "")?.split(",");

  const items = props.searchable
    ? props.options?.filter((item) => {
        if (searchBy?.length > 1) {
          let found = false;
          for (let i = 0; i < searchBy?.length; i++) {
            found = regexp.test(item[searchBy[i]]);
            if (found) {
              break;
            }
          }
          return found;
        } else {
          return regexp.test(item[props.searchBy] || item[props.labelField]);
        }
      })
    : props.options;

  return (
    <>
      {!items?.length && (
        <span className="px-lg py-sm font-manrope text-xs font-medium text-primary">
          {messages?.common?.noOption}
        </span>
      )}

      {items?.map((option) => {
        const foundIndex = state.values.findIndex(
          (v) => v.value === option.value,
        );
        const found = foundIndex >= 0;

        return (
          <DropdownItem
            option={option}
            onClick={() => methods.addItem(option)}
            found={found}
            showAvatar={showAvatar}
            customOptionItem={customOptionItem}
          />
        );
      })}
    </>
  );
};

export default SingleSelectItem;
