import Tooltip from "@shared/ui/Tooltip";
import React from "react";

type InputBoxPropsType = {
  keyId: string;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
  dataTip?: string;
  required?: boolean;
  hasError?: boolean;
  setRef?: (_: HTMLInputElement) => void;
  customInputClasses?: string;
};

const InputBox = ({
  keyId,
  value = undefined,
  onChange = () => {},
  autoFocus = false,
  dataTip = "",
  required = false,
  hasError = false,
  setRef = null,
  className = "",
  customInputClasses = "",
  ...props
}: React.ComponentPropsWithoutRef<"input"> & InputBoxPropsType) => {
  const classes: string = [
    "w-full h-4xl px-md rounded-lg font-manrope font-medium text-xs text-primary border border-solid transition-all duration-200 ease-in-out",
    "placeholder:text-secondary placeholder:opacity-100 placeholder:disabled:text-disabled disabled:placeholder:opacity-100",
    "focus:bg-primary focus:border-brand focus:outline-0",
    "disabled:text-disabled disabled:bg-accent disabled:border-transparent disabled:opacity-100 read-only:text-disabled read-only:bg-accent read-only:border-transparent read-only:opacity-100",
    customInputClasses ||
      (hasError
        ? "bg-primary border-danger focus:bg-primary focus:border-danger"
        : value || value === 0
        ? "bg-primary border-primary"
        : "bg-accent border-transparent"),
    className,
  ].join(" ");

  return (
    <>
      <input
        value={value}
        id={keyId}
        className={classes}
        onChange={onChange}
        autoFocus={autoFocus}
        data-tip={dataTip}
        data-for="inputTooltip"
        required={required}
        ref={setRef}
        {...props}
      />

      {dataTip && <Tooltip id="inputTooltip" />}
    </>
  );
};

export default InputBox;
