import { getAllRoles } from "@shared/utils/getAllRoles";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useRecoilState } from "recoil";

import { navigationAtom } from "~/components/_navigation";
import useAuth from "~/components/general/_use-auth";

const ROLES = getAllRoles();

export default ({ intl, routes = [], DefaultRoute, user, appConfig }) => (
  <Switch>
    {routes.map((route, index) => (
      <Route
        key={index}
        path={`/${intl.locale}${route.path}`}
        exact={route.exact}
      >
        <Main>
          <route.Main
            intl={intl}
            route={route}
            user={user}
            appConfig={appConfig}
          />
        </Main>
      </Route>
    ))}

    <Redirect to={`/${intl.locale}${DefaultRoute.path}`} />
  </Switch>
);

const Main = ({ children }) => {
  const [navigationOptions] = useRecoilState(navigationAtom);
  const { user } = useAuth();

  const isGMU = React.useMemo(
    () => user?.role === ROLES.GMU.VALUE,
    [user?.role],
  );

  return (
    <main
      className={`o-page ${
        navigationOptions.isCollapsed ? "navigation-collapsed" : ""
      } ${isGMU ? "full-page-width" : ""}`}
    >
      <>{children}</>
    </main>
  );
};
