import React from "react";
import { Waypoint } from "react-waypoint";

import EmptySpace from "~/components/_emptySpace";
import { NotFoundComponent } from "~/components/_notFoundMessage";
import Spinner from "~/components/_spinner";
import AssetRow from "~/components/asset/AssetRow";
import MachineListItem from "~/components/machine/MachineListItem";
import { MachineLoaderComponent } from "~/components/skeletonLoaders/_machineLoders";
import { ITEMS_BEFORE_PAGE, ITEMS_PER_PAGE } from "~/constants/global";
import { NoUserAssignedIcon } from "~/icons";

export default ({
  intl,
  machines,
  loading,
  totalCount,
  handleFetchMore,
  isAsset = false,
  searchValue = "",
}) => {
  const handleScrollToBottom = (event) => {
    if (
      machines.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== "above" &&
      machines.length < totalCount
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: machines.length,
      });
    }
  };

  if (loading && !machines.length) {
    return <Spinner />;
  }

  return (
    <div>
      {isAsset ? (
        machines?.length === 0 && !loading ? (
          <NotFoundComponent
            icon={<NoUserAssignedIcon />}
            title={
              searchValue
                ? intl.messages?.common?.noResultFound
                : isAsset
                ? intl.messages?.hierarchy?.assets?.noAssets
                : intl.messages?.machines?.noMachines
            }
          />
        ) : (
          <div className="flex flex-col space-y-sm">
            {machines?.map((item) => (
              <AssetRow
                key={item._id}
                intl={intl}
                asset={item}
                isAssetQuery={!!searchValue}
              />
            ))}
            <Waypoint onEnter={handleScrollToBottom} />
          </div>
        )
      ) : (
        machines?.map((item, index) => (
          <React.Fragment key={item._id}>
            <MachineListItem intl={intl} machine={item} inline />
            {index === machines.length - ITEMS_BEFORE_PAGE && (
              <Waypoint onEnter={handleScrollToBottom} />
            )}
          </React.Fragment>
        ))
      )}
      {loading && <MachineLoaderComponent total={1} />}
      <EmptySpace height="30px" />
    </div>
  );
};
