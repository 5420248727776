export default [
  "login",
  "videoCall",
  "forgotPassword",
  "forgotPasswordSuccess",
  "common",
  "navigation",
  "header",
  "hierarchy",
  "tickets",
  "settings",
  "documentation",
  "errors",
  "parts",
  "machines",
  "preventiveMaintenance",
  "productionLines",
  "assets",
  "components",
];
