import { Avatar, AVATAR_SIZES } from "@shared/ui/Avatar";
import { File, LinkBreak } from "phosphor-react";
import React, { useState, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import MessgaeSpinner from "./_messageSpinner";

import { chatAtom, chatImagesAtom, imagePreviewAtom } from "~/atoms/_chat";

const renderFileSize = (common, bytes) => {
  if (!bytes) {
    return common.fileSizeUnknown;
  }

  const bytesToMegaBytes = bytes / (1024 * 1024);
  const rounded = Math.round((bytesToMegaBytes + Number.EPSILON) * 100) / 100;

  return `${common?.fileSize} ${rounded}MB.`;
};

const RenderVideo = ({ common, fileSize, file }) => {
  const chatOptions = useRecoilValue(chatAtom);
  const [fileData, setFileData] = useState({});

  const downloadedVideosRef = useRef([]);

  return (
    <div
      className="c-chat-boxMediaMessageExtraInfo"
      onClick={() => {
        if (!downloadedVideosRef.current?.includes(file.id)) {
          setFileData({
            ...fileData,
            loading: true,
          });
          chatOptions.pubnub
            .onDownload(file)
            .then((data) => {
              downloadedVideosRef.current = [
                ...downloadedVideosRef.current,
                file.id,
              ];
              setFileData({ ...data, loading: false });
            })
            .catch(() => {
              downloadedVideosRef.current = [
                ...downloadedVideosRef.current,
                file.id,
              ];
              setFileData({
                type: "image/svg+xml",
                url: "/images/default/thumbnail.svg",
                loading: false,
              });
            });
        }
      }}
    >
      {!fileData?.url ? (
        fileData?.loading ? (
          <MessgaeSpinner />
        ) : (
          <>
            <i className="fas fa-video" />
            <span className="c-chat-boxMediaMessageExtraInfoLabel">
              {common?.clickToPlayVideo}
            </span>
            <span className="c-chat-boxMediaMessageExtraInfoLabel">
              {renderFileSize(common, fileSize)}
            </span>
          </>
        )
      ) : fileData?.type.includes("video") ? (
        <video
          controls
          autoPlay={true}
          className="c-chat-boxMediaMessageFile c-chat-boxMediaMessageFile--visible"
        >
          <source src={fileData?.url} type={fileData?.type} />
        </video>
      ) : (
        <>
          <i className="fas fa-video-slash" />
          <span className="c-chat-boxMediaMessageExtraInfoLabel">
            {common.mediaFileNotFound}
          </span>
          <span className="c-chat-boxMediaMessageExtraInfoLabel">
            {renderFileSize(common, fileSize)}
          </span>
        </>
      )}
    </div>
  );
};

const RenderAudio = ({ common, fileSize, file }) => {
  const chatOptions = useRecoilValue(chatAtom);
  const [fileData, setFileData] = useState();
  const downloadedAudiosRef = useRef([]);

  return (
    <div
      className="c-chat-boxMediaMessageExtraInfo"
      onClick={() => {
        if (!downloadedAudiosRef.current?.includes(file.id)) {
          setFileData({
            ...fileData,
            loading: true,
          });
          chatOptions.pubnub
            .onDownload(file)
            .then((data) => {
              downloadedAudiosRef.current = [
                ...downloadedAudiosRef.current,
                file.id,
              ];
              setFileData({ ...data, loading: false });
            })
            .catch(() => {
              downloadedAudiosRef.current = [
                ...downloadedAudiosRef.current,
                file.id,
              ];
              setFileData({
                type: "image/svg+xml",
                url: "/images/default/thumbnail.svg",
                loading: false,
              });
            });
        }
      }}
    >
      {!fileData?.url ? (
        fileData?.loading ? (
          <MessgaeSpinner />
        ) : (
          <>
            <i className="fas fa-music" />
            <span className="c-chat-boxMediaMessageExtraInfoLabel">
              {common.clickToPlayAudio}
            </span>
            <span className="c-chat-boxMediaMessageExtraInfoLabel">
              {renderFileSize(common, fileSize)}
            </span>
          </>
        )
      ) : fileData?.type.includes("audio") ? (
        <audio
          src={fileData?.url}
          controls
          autoPlay={true}
          className="c-chat-boxMediaMessageFile c-chat-boxMediaMessageFile--audio c-chat-boxMediaMessageFile--visible"
        ></audio>
      ) : (
        <>
          <i className="fas fa-unlink" />
          <span className="c-chat-boxMediaMessageExtraInfoLabel">
            {common.mediaFileNotFound}
          </span>
          <span className="c-chat-boxMediaMessageExtraInfoLabel">
            {renderFileSize(common, fileSize)}
          </span>
        </>
      )}
    </div>
  );
};

const RenderImage = ({ file }) => {
  const chatOptions = useRecoilValue(chatAtom);
  const [chatImages, setChatImages] = useRecoilState(chatImagesAtom);
  const [imagePreviewOptions, setImagePreview] =
    useRecoilState(imagePreviewAtom);

  const [fileData, setFileData] = useState();
  const downloadedImagesRef = useRef([]);

  chatOptions?.pubnub
    ?.onDownload(file)
    .then((data) => {
      if (!downloadedImagesRef.current?.includes(file.id)) {
        downloadedImagesRef.current = [...downloadedImagesRef.current, file.id];
        setFileData({ ...data });
      }
    })
    .catch(() => {
      if (!downloadedImagesRef.current?.includes(file.id)) {
        downloadedImagesRef.current = [...downloadedImagesRef.current, file.id];
        setFileData({
          type: "image/svg+xml",
          url: "/images/default/thumbnail.svg",
        });
      }
    });

  if (!fileData?.url) {
    return <MessgaeSpinner />;
  } else {
    return (
      <img
        onClick={(e) => {
          setImagePreview({
            ...imagePreviewOptions,
            openedImage: e.target.src,
            isOpen: true,
          });
        }}
        onLoad={(e) => {
          setChatImages({
            ...chatImages,
            images: [...chatImages?.images, e.target.src],
          });
        }}
        className={
          "c-chat-boxMediaMessageFile c-chat-boxMediaMessageFile--image c-chat-boxMediaMessageFile--visible"
        }
        src={fileData?.url}
      />
    );
  }
};

const RenderFile = ({ common, fileSize, file }) => {
  const [fileData, setFileData] = useState({ loading: false });

  const chatOptions = useRecoilValue(chatAtom);

  return (
    <div className="c-chat-boxMediaContent">
      <div className="c-chat-boxMediaMessageWrapper">
        <a
          className="u-flex u-items-center"
          onClick={(e) => {
            e.preventDefault();
            if (fileData?.loading || fileData?.error) {
              return;
            }
            setFileData({ loading: true });
            chatOptions?.pubnub
              ?.downloadFileLocaly(file)
              .then(() => {
                setFileData({ loading: false, error: false });
              })
              .catch(() => {
                setFileData({ loading: false, error: true });
              });
          }}
        >
          <div className="c-chat-boxMediaMessageAttachmentIcon u-margin-r-1">
            {!fileData?.loading ? (
              !fileData?.error ? (
                <File size={16} />
              ) : (
                <LinkBreak size={16} />
              )
            ) : (
              <MessgaeSpinner />
            )}
          </div>
          <div className="u-flex u-flex-column">
            <div className="c-chat-boxMediaMessageAttachmentLabel">
              {fileData?.error ? (
                common.fileNotFound
              ) : (
                <span title={file.name}>{file.name}</span>
              )}
            </div>
            <div className="c-chat-boxMediaMessageAttachmentMeta">
              {renderFileSize(common, fileSize)}
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

const ChatMessageItemFile = ({ intl, message }) => {
  const { name, isOemUser, isFacilityUser } = message?.message?.sender || "";
  const common = intl.messages.common;

  if (message?.message?.sending) {
    return (
      <div
        className={`c-chat-boxMedia ${!isOemUser && "c-chat-boxMedia--sender"}`}
      >
        {isOemUser && (
          <Avatar
            className="mr-md"
            name={name}
            size={AVATAR_SIZES.MEDIUM}
            containerClassName="self-start"
          />
        )}

        <div className="c-chat-boxMediaContent">
          <div className="c-chat-boxMediaMessage">
            <div className="u-relative">
              <MessgaeSpinner text={common.upload.file} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (message?.message?.file && !message?.message?.sending) {
    return (
      <div
        key={message?.timetoken}
        className={`c-chat-boxMedia ${!isOemUser && "c-chat-boxMedia--sender"}`}
      >
        {isOemUser && (
          <Avatar
            className="mr-md"
            name={name}
            size={AVATAR_SIZES.MEDIUM}
            containerClassName="self-start"
          />
        )}

        <div className="c-chat-boxMediaContent">
          <div
            className={`c-chat-boxMediaMessage c-chat-mediaFile ${
              !isOemUser && "c-chat-boxMedia--sender"
            }`}
          >
            <div className="u-relative" data-type={message?.message?.type}>
              {!message?.message?.type?.includes("heic") &&
                message?.message?.type?.includes("image") && (
                  <RenderImage
                    common={common}
                    fileSize={message?.message?.size}
                    file={message?.message?.file}
                  />
                )}

              {message?.message?.type?.includes("audio") && (
                <RenderAudio
                  common={common}
                  fileSize={message?.message?.size}
                  file={message?.message?.file}
                />
              )}

              {message?.message?.type?.includes("video") &&
                (["webm", "mp4", "ogg"].find((type) =>
                  message?.message?.type?.includes?.(type),
                ) ? (
                  <RenderVideo
                    common={common}
                    fileSize={message?.message?.size}
                    file={message?.message?.file}
                  />
                ) : (
                  <RenderFile
                    common={common}
                    fileSize={message?.message?.size}
                    file={message?.message?.file}
                  />
                ))}
              {(message?.message?.type?.includes("heic") ||
                !["video", "audio", "image"].includes(
                  message?.message?.type?.split("/")[0],
                )) && (
                <RenderFile
                  common={common}
                  fileSize={message?.message?.size}
                  file={message?.message?.file}
                />
              )}
            </div>
            {/* {message?.message?.file?.name} */}
          </div>

          <span className="c-chat-boxMediaTime">
            {name || ""}
            {", "}
            {new Date(message?.timetoken / 10000)?.toLocaleTimeString(
              intl?.locale,
              {
                hour: "2-digit",
                minute: "2-digit",
              },
            )}
          </span>
        </div>
      </div>
    );
  }

  return null;
};

export default ChatMessageItemFile;
