export const MESSAGE_TYPE = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
} as const;

export type MessageType = (typeof MESSAGE_TYPE)[keyof typeof MESSAGE_TYPE];

const MESSAGE_STYLES: Record<MessageType, string> = {
  [MESSAGE_TYPE.PRIMARY]: "bg-brand-lightest rounded-l-xl",
  [MESSAGE_TYPE.SECONDARY]: "bg-accent rounded-r-xl",
};

type ChatMessagePropsType = {
  children: React.ReactNode;
  type?: MessageType;
  className?: string;
};

const ChatMessage = ({
  children,
  type = MESSAGE_TYPE.PRIMARY,
  className = "",
}: ChatMessagePropsType) => (
  <div
    className={`flex flex-col space-y-md p-md rounded-b-xl max-w-xs ${MESSAGE_STYLES[type]} ${className}`}
  >
    {children}
  </div>
);

export default ChatMessage;
