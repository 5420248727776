import { atom } from "recoil";

export const headerAtom = atom({
  key: "headerOptions",
  default: {
    title: "",
    lead: "",
    url: "",
  },
});
