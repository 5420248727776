import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { generateInitials } from "@shared/utils/textFormatting";
import { User } from "phosphor-react";

export const AVATAR_SIZES = {
  X_LARGE: "xLarge",
  LARGE: "large",
  MEDIUM: "medium",
  SMALL: "small",
  X_SMALL: "xSmall",
} as const;

export type AvatarSizeType = (typeof AVATAR_SIZES)[keyof typeof AVATAR_SIZES];

const SIZE_STYLES: Record<AvatarSizeType, string> = {
  [AVATAR_SIZES.X_LARGE]: "w-5xl h-5xl",
  [AVATAR_SIZES.LARGE]: "w-4xl h-4xl",
  [AVATAR_SIZES.MEDIUM]: "w-3xl h-3xl",
  [AVATAR_SIZES.SMALL]: "w-2xl h-2xl",
  [AVATAR_SIZES.X_SMALL]: "w-xl h-xl",
};

const getLabelSize = (size: AvatarSizeType) => {
  switch (size) {
    case AVATAR_SIZES.X_LARGE:
      return LABEL_SIZES.LARGE;

    case AVATAR_SIZES.LARGE:
    case AVATAR_SIZES.MEDIUM:
      return LABEL_SIZES.SMALL;

    case AVATAR_SIZES.SMALL:
    case AVATAR_SIZES.X_SMALL:
      return LABEL_SIZES.X_SMALL;
  }
};

type AvatarPropsType = {
  size?: AvatarSizeType;
  className?: string;
  showTooltip?: boolean;
  name?: string;
  showName?: boolean;
  nameClassName?: string;
  placeholderIconClassName?: string;
  containerClassName?: string;
  imageSrc?: string;
  hasNoUser?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  avatarRef?: (_: HTMLDivElement) => void;
  style?: React.CSSProperties;
};

const Avatar = ({
  size = AVATAR_SIZES.SMALL,
  className = "",
  showTooltip = false,
  name = "",
  showName = false,
  nameClassName = "",
  placeholderIconClassName = "",
  containerClassName = "",
  imageSrc = null,
  hasNoUser = false,
  onClick = () => {},
  avatarRef = null,
  style = null,
}: AvatarPropsType) => (
  <div
    className={`flex items-center ${containerClassName}`}
    onClick={onClick}
    ref={avatarRef}
  >
    {imageSrc ? (
      <img
        src={imageSrc}
        alt={name}
        className={`relative grow-0 shrink-0 rounded-full object-contain ${SIZE_STYLES[size]}`}
      />
    ) : (
      <div
        title={showTooltip ? name : ""}
        className={`relative grow-0 shrink-0 select-none flex items-center justify-center rounded-full ${
          hasNoUser ? "bg-tertiary" : "bg-brand-lightest"
        }  ${SIZE_STYLES[size]} ${showName ? "mr-sm" : ""} ${className}`}
        style={style}
      >
        {hasNoUser ? (
          <User
            size={12}
            className={`${placeholderIconClassName || "text-secondary"}`}
          />
        ) : (
          <Label
            size={getLabelSize(size)}
            color="text-brand"
            className="uppercase"
          >
            {generateInitials(name)}
          </Label>
        )}
      </div>
    )}
    {showName && (
      <BodyText
        size={BODY_TEXT_SIZES.X_SMALL}
        className={nameClassName}
        color={hasNoUser ? "text-secondary" : "text-primary"}
      >
        {name}
      </BodyText>
    )}
  </div>
);

export default Avatar;
