import DeleteIcon from "@shared/svg/delete-icon.svg?react";
import Button, { BUTTON_SIZES, BUTTON_VARIANTS } from "@shared/ui/Button";
import { Counter, CounterPropsType } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { useIntl } from "react-intl";

type PartListItemPropsType = {
  articleNumber: string;
  name: string;
  thumbnail: string;
  hideDetailsButton?: boolean;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  showDeleteButton: boolean;
  onDeleteButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  counterProps: CounterPropsType;
  customLabelStyles?: string;
};

const PartListItem = ({
  articleNumber,
  name,
  thumbnail,
  hideDetailsButton = false,
  onButtonClick = () => {},
  showDeleteButton,
  onDeleteButtonClick,
  counterProps,
  customLabelStyles = "",
}: PartListItemPropsType) => {
  const { messages } = useIntl();

  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-center max-sm:gap-md justify-between cursor-pointer mb-lg p-md border border-solid border-primary bg-primary hover:bg-accent rounded-lg overflow-hidden">
      <div className="flex items-center gap-lg">
        <img
          src={thumbnail}
          alt={name}
          className="w-5xl h-4xl object-cover object-center rounded-lg"
        />
        <div className={`flex flex-col overflow-hidden ${customLabelStyles}`}>
          <Label
            size={LABEL_SIZES.SMALL}
            color="text-secondary"
            className="truncate max-w-48"
          >
            {articleNumber}
          </Label>
          <Label size={LABEL_SIZES.SMALL} className="truncate max-w-48">
            {name}
          </Label>
          {!hideDetailsButton && (
            <div>
              <Button
                variant={BUTTON_VARIANTS.LINK}
                text={messages.common.details}
                onClick={onButtonClick}
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center gap-lg max-sm:w-full max-sm:justify-between max-sm:flex-row-reverse">
        {showDeleteButton && (
          <Button
            variant={BUTTON_VARIANTS.OUTLINE}
            className="!bg-primary"
            size={BUTTON_SIZES.SMALL}
            leadingIcon={<DeleteIcon width={16} height={16} />}
            onClick={onDeleteButtonClick}
          />
        )}
        <Counter
          count={counterProps.count}
          setCount={counterProps.setCount}
          incrementCount={counterProps.incrementCount}
          decrementCount={counterProps.decrementCount}
          isReadOnlyInput={counterProps.isReadOnlyInput}
        />
      </div>
    </div>
  );
};

export default PartListItem;
