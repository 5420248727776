import { FolderLock, FolderOpen } from "phosphor-react";
import * as React from "react";

import PAID_FEATURES from "$/settings/paid-features.json";
import SingleTicket from "~/containers/_singleTicket.jsx";
import ClosedWorkOrders from "~/containers/work-order/ClosedWorkOrders.jsx";
import WorkOrders from "~/containers/work-order/WorkOrders.jsx";
import { getEnums } from "~/utils";

const paidFeaturesRef = getEnums(PAID_FEATURES, "reference");

export const ticketRoutes = (WithHeader) => {
  return [
    {
      id: "app",
      path: "/app/work-orders",
      exact: true,
      label: "work-orders",
      icon: <FolderOpen className="icon" size={20} />,
      iconActive: (
        <FolderOpen className="icon-active" size={20} weight="fill" />
      ),
      key: "openTickets",
      showInNavigation: true,
      enableIf: (user) =>
        user?.oem?.paidFeatures?.includes?.(paidFeaturesRef.workManagement),
      Main: (props) => {
        var header = () => {
          return {
            title: props.intl.messages.header?.ticketListing?.title,
            navigation: props.intl.messages?.navigation?.["open-tickets"],
            backIcon: false,
            page: "open-tickets",

            action: {
              label: props.intl.messages.header?.ticketListing?.action,
              // url: `/${props?.intl?.locale} /app/open-tickets/create`,
            },
            counter: {},
          };
        };

        return (
          <WithHeader {...props} header={header()}>
            <WorkOrders {...props} header={header()} />
          </WithHeader>
        );
      },
    },
    {
      id: "app",
      path: "/app/closed-work-orders",
      exact: true,
      label: "closed-work-orders",
      icon: <FolderLock className="icon" size={20} />,
      iconActive: (
        <FolderLock className="icon-active" size={20} weight="fill" />
      ),
      key: "closedTickets",
      showInNavigation: true,
      enableIf: (user) =>
        user?.oem?.paidFeatures?.includes?.(paidFeaturesRef.workManagement),
      Main: (props) => {
        var header = () => {
          return {
            title: props.intl.messages.header?.ticketListing?.title,
            navigation: props.intl.messages?.navigation?.["closed-tickets"],
            backIcon: false,
            page: "open-tickets",
            action: {
              label: props.intl.messages.header?.ticketListing?.action,
              // url: `/${props?.intl?.locale} /app/open-tickets/create`,
            },
            counter: {},
          };
        };

        return (
          <WithHeader {...props} header={header()}>
            <ClosedWorkOrders {...props} header={header()} />
          </WithHeader>
        );
      },
    },
    {
      id: "app",
      path: "/app/work-orders/:id",
      exact: true,
      label: "My tickets",
      icon: "fas fa-check-circle",
      iconActive: (
        <FolderOpen className="icon-active" size={20} weight="fill" />
      ),
      showInNavigation: false,
      enableIf: (user) =>
        user?.oem?.paidFeatures?.includes?.(paidFeaturesRef.workManagement),
      Main: (props) => {
        var header = () => {
          return {
            backIcon: true,
            backUrl: "/en/app/work-orders",
            headerClass: "desktop-hidden",
          };
        };

        return (
          <WithHeader {...props} header={header()}>
            <SingleTicket header={header()} {...props} />
          </WithHeader>
        );
      },
    },
    {
      id: "app",
      path: "/app/closed-work-orders/:id",
      exact: true,
      label: "Closed ticket",
      icon: "",
      iconActive: (
        <FolderLock className="icon-active" size={20} weight="fill" />
      ),
      showInNavigation: false,
      enableIf: (user) =>
        user?.oem?.paidFeatures?.includes?.(paidFeaturesRef.workManagement),
      Main: (props) => {
        var header = () => {
          return {
            backIcon: true,
            backUrl: "/en/app/closed-work-orders",
            headerClass: "desktop-hidden",
            ticketType: "closed",
          };
        };

        return (
          <WithHeader {...props} header={header()}>
            <SingleTicket {...props} header={header()} />
          </WithHeader>

          // <SingleTicket header={header()} {...props} />
        );
      },
    },
  ];
};
