import Accordion, { ACCORDION_VARIANTS } from "@shared/ui/Accordion";
import AppHeader from "@shared/ui/AppHeader";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Breadcrumbs from "@shared/ui/Breadcrumbs";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Drawer from "@shared/ui/Drawer";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { SearchInput } from "@shared/ui/Inputs";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import TextEditor, { serialize } from "@shared/ui/TextEditor";
import { Plus } from "phosphor-react";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams, Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState } from "recoil";

import getEnums from "$/settings/enums";
import TEMPLATE_TYPES from "$/settings/enums/machineTemplate/index.json";
import PAID_FEATURES from "$/settings/paid-features.json";
import { OpenTicketsAtom } from "~/atoms/_openTicket";
import EmptySpace from "~/components/_emptySpace";
import Machine3DTab from "~/components/_machine3DTab";
import SubAssets from "~/components/asset/SubAssets";
import BoxUIElement from "~/components/boxUIElement";
import ContentLoading from "~/components/ContentLoading";
import useAuth from "~/components/general/_use-auth";
import Hamburger from "~/components/header/Hamburger";
import MobileSearch from "~/components/header/MobileSearch";
import ComponentsTab from "~/components/machine/ComponentsTab";
import { MachineHistory } from "~/components/machine/MachineHistory";
import PreventiveMaintenance from "~/components/machine/preventiveMaintenance/PreventiveMaintenance";
import PartsTableWithPreview from "~/components/parts/_parts-table-with-preview";
import PartsPreview from "~/components/parts/_partsPreview";
import UnderlineTabsWrapper from "~/components/UnderlineTabsWrapper";
import { APP_FEATURES_ENUM } from "~/constants";
import {
  MACHINE_DETAIL_PARTS_TAB_INDEX,
  MAX_TEXT_DISPLAY_LENGTH,
} from "~/constants/global";
import { MACHINE_DOCUMENTATION_STATUSES } from "~/constants/machine";
import CreateWorkOrder from "~/containers/work-order/Create";
import {
  getComponentsByParams,
  useGetMachineById,
  useGetMachinePartsById,
  listAllPreventiveMaintenanceEvents,
} from "~/services";
import { useGetAppConfig } from "~/services/appConfig/query";
import { useGetAssetById, useGetAssetPartsById } from "~/services/asset";
import { getMachineThumbnail } from "~/utils";
import { trimText } from "~/utils/_initials";

const templateTypes = getEnums(TEMPLATE_TYPES, "reference");
const paidFeaturesRef = getEnums(PAID_FEATURES, "reference");

const SingleMachine = ({ intl }) => {
  const { user } = useAuth();
  const { id } = useParams();
  const { messages } = useIntl();
  const history = useHistory();
  const [showPreview, setShowPreview] = React.useState(false);
  const [selectedPart, setSelectedPart] = React.useState(null);
  const [partSearchTerm, setPartSearchTerm] = React.useState("");
  const [componentSearchTerm, setComponentSearchTerm] = React.useState("");
  const [openTicketCoil, setOpenTicketCoil] = useRecoilState(OpenTicketsAtom);

  const isProductionLinePaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef?.productionLines,
  );
  const isMachineDocumentationEnabled =
    user.facility.isMachineDocumentationEnabled;
  const isPreventiveMaintenanceEventsEnabled =
    user.facility.isPreventiveMaintenanceEventsEnabled;
  const isComponentsPaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef?.components,
  );
  const is3DModelPaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef?._3DModels,
  );

  const { appConfig } = useGetAppConfig();

  const isHierarchyEnabled = appConfig?.features?.includes(
    APP_FEATURES_ENUM.hierarchy,
  );

  // Calling get queries
  const [asset] = useGetAssetById({ id, skipCondition: !isHierarchyEnabled });
  const [assetParts, assetPartsLoading] = useGetAssetPartsById({
    id,
    skipCondition: !isHierarchyEnabled,
  });

  const [machine] = useGetMachineById({
    id,
    skipCondition: isHierarchyEnabled,
  });
  const [inventoryParts, inventoryPartsLoading] = useGetMachinePartsById({
    id,
    skipCondition: isHierarchyEnabled,
  });

  const resource = isHierarchyEnabled ? asset : machine;
  const resourceParts = isHierarchyEnabled ? assetParts : inventoryParts;
  const resourcePartsLoading = isHierarchyEnabled
    ? assetPartsLoading
    : inventoryPartsLoading;

  const {
    components = [],
    loading: componentsLoading,
    totalCount,
    handleFetchMore,
  } = getComponentsByParams({
    where: {
      machine: id,
      searchQuery: componentSearchTerm,
    },
    skipCondition: isHierarchyEnabled,
  });

  const machineDescription = useMemo(() => {
    let description = "";
    if (serialize(resource?.description)) {
      description = resource?.description;
    } else {
      if (resource?.template?.type === templateTypes?.machineTemplate) {
        description = resource?.template?.description;
      } else {
        description = "";
      }
    }
    return description;
  }, [resource]);

  // Get Preventive Maintanance Events
  const { events, loading: fetchingEvents } =
    listAllPreventiveMaintenanceEvents({
      where: {
        oem_in: user?.oem?._id,
        machine_in: id,
      },
    });

  const handlePreviewClose = () => {
    setShowPreview(false);
    setSelectedPart(null);
  };

  const installationDate = useMemo(() => {
    return resource?.customFields?.find(
      (field) =>
        field?.fieldId?.slug === "installation-date" &&
        field?.fieldId?.isAdditionalField,
    );
  }, [resource]);

  const assetType = user?.oem?.assetTypes?.find(
    (item) => item._id === asset.assetType,
  );

  // Filtered Parts or All Parts
  const filteredMchineParts = useMemo(() => {
    return partSearchTerm
      ? resourceParts?.filter((part) => {
          return (
            part?.part?.name
              ?.toLowerCase()
              .includes(partSearchTerm?.toLowerCase()) ||
            part?.part?.articleNumber
              ?.toLowerCase()
              .includes(partSearchTerm?.toLowerCase())
          );
        })
      : resourceParts;
  }, [partSearchTerm, resourceParts]);

  const handleSearch = (value) => {
    setPartSearchTerm(value);
  };

  const renderMacineThumbnail = () => {
    let thumbnailToShow = getMachineThumbnail(resource, true);

    return (
      <>
        {thumbnailToShow && (
          <figure className="machine-thumbnail">
            <img
              src={thumbnailToShow}
              alt={resource?.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/images/default/thumbnail.svg";
              }}
            />
          </figure>
        )}
      </>
    );
  };

  const breadCrumbs = React.useMemo(
    () =>
      [
        {
          label: intl?.messages?.assets.breadCrumbs.assets,
          link: `/${intl?.locale}/app/assets`,
        },
        !isHierarchyEnabled
          ? {
              label: intl?.messages?.assets.breadCrumbs.machines,
              link: `/${intl?.locale}/app/assets/machines`,
            }
          : null,
        { label: trimText(resource?.name, MAX_TEXT_DISPLAY_LENGTH) || "" },
      ].filter((item) => item),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isHierarchyEnabled, resource?.name],
  );

  const createWorkOrder = (isPartsRequest = false, partLabel = "") =>
    setOpenTicketCoil({
      ...openTicketCoil,
      isDrawerOpen: true,
      defaultSelectedMachine: { ...resource, inventoryParts: resourceParts },
      isPartsRequest,
      description: partLabel,
    });

  return (
    <>
      <CreateWorkOrder buttonComponent={() => {}} />
      <div className="single-machine-container">
        <button
          className={`btn-v2 primary-btn-v2 single-machine-create-work-order-mobile-button ${
            history.location.state?.activeTab === MACHINE_DETAIL_PARTS_TAB_INDEX
              ? "above-footer"
              : ""
          }`}
          onClick={() => createWorkOrder()}
        >
          <Plus size={20} />
        </button>
        <div className="sticky top-0 z-[5] px-2xl bg-inverse flex items-center w-full max-md:mb-md">
          <AppHeader padding="py-2xl lg:py-lg" className="w-full">
            <Breadcrumbs options={breadCrumbs} />

            <Button
              text={messages?.header?.ticketListing?.action}
              className="ml-sm max-lg:hidden"
              onClick={() => createWorkOrder()}
            />

            {/* Hamburger component is only for mobile view */}
            <Hamburger />
          </AppHeader>
        </div>
        <div className="fw-page-content-wrapper u-flex u-flex-wrap u-width-100 u-padding-r-0">
          <div className="fw-page-main-content u-width-100">
            <div className="fw-machine-title">
              <Headline
                size={HEADLINE_SIZES.SMALL}
                className="max-md:line-clamp-2 max-md:text-ellipsis max-md:whitespace-normal"
              >
                {trimText(resource?.name, 75)}
              </Headline>
            </div>
            <div className="page-details-tab">
              <UnderlineTabsWrapper
                tabs={[
                  {
                    id: "general-information",
                    label:
                      intl?.messages?.machines?.machineDetails.titleTabs
                        .details,
                    content: (
                      <div className="machine-details-container u-width-100">
                        <div className="machine-details-content u-width-100">
                          <div className="machine-meta-container u-items-center">
                            {renderMacineThumbnail()}
                            <div className="machine-meta">
                              <Label size={LABEL_SIZES.SMALL}>
                                {messages?.machines?.details}
                              </Label>
                              <EmptySpace height="14px"></EmptySpace>
                              <div className="u-flex u-width-100">
                                <div className="machine-meta-info u-margin-r-5">
                                  <BodyText
                                    size={BODY_TEXT_SIZES.X_SMALL}
                                    color="text-secondary"
                                    className="max-md:mr-2xs no-styles"
                                  >
                                    {intl?.messages?.machines?.serialNum + ":"}
                                  </BodyText>
                                  <EmptySpace height="4px"></EmptySpace>
                                  <BodyText
                                    size={BODY_TEXT_SIZES.X_SMALL}
                                    className="no-styles"
                                  >
                                    {trimText(resource?.serialNumber, 75)}
                                  </BodyText>
                                </div>
                                {installationDate?.fieldId?.enabled && (
                                  <div className="machine-meta-info u-margin-r-5">
                                    <BodyText
                                      size={BODY_TEXT_SIZES.X_SMALL}
                                      color="text-secondary"
                                      className="max-md:mr-2xs no-styles"
                                    >
                                      {messages?.machines?.installationDate +
                                        ":"}
                                    </BodyText>
                                    <EmptySpace height="4px"></EmptySpace>
                                    <BodyText
                                      size={BODY_TEXT_SIZES.X_SMALL}
                                      className="no-styles"
                                    >
                                      {installationDate?.value}
                                    </BodyText>
                                  </div>
                                )}
                                {isHierarchyEnabled && (
                                  <div className="machine-meta-info mr-7 flex items-center md:items-start md:flex-col md:space-y-xs">
                                    <BodyText
                                      size={BODY_TEXT_SIZES.X_SMALL}
                                      color="text-secondary"
                                      className="max-md:mr-2xs no-styles"
                                    >
                                      {`${messages["hierarchy"]["assetTypes"]["nameText"]}:`}
                                    </BodyText>
                                    <BodyText
                                      size={BODY_TEXT_SIZES.X_SMALL}
                                      className="no-styles"
                                    >
                                      {assetType?.name ?? "---"}
                                    </BodyText>
                                  </div>
                                )}
                                {!isHierarchyEnabled &&
                                  isProductionLinePaid &&
                                  resource?.productionLine && (
                                    <div className="machine-meta-info">
                                      <BodyText
                                        size={BODY_TEXT_SIZES.X_SMALL}
                                        color="text-secondary"
                                        className="max-md:mr-2xs no-styles"
                                      >
                                        {intl?.messages?.machines
                                          ?.machineDetails?.detailsTab
                                          ?.productionLine + ":"}
                                      </BodyText>
                                      <EmptySpace height="4px"></EmptySpace>
                                      <Link
                                        to={`/${intl.locale}/app/assets/production-lines/${resource?.productionLine?._id}`}
                                      >
                                        <BodyText
                                          size={BODY_TEXT_SIZES.X_SMALL}
                                          color="text-brand"
                                          className="cursor-pointer"
                                        >
                                          {trimText(
                                            resource?.productionLine?.name,
                                            MAX_TEXT_DISPLAY_LENGTH,
                                          )}
                                        </BodyText>
                                      </Link>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          <Accordion
                            variant={ACCORDION_VARIANTS.DESCRIPTION}
                            title={messages?.machines?.description}
                          >
                            <TextEditor
                              keyId="machine-description"
                              content={machineDescription}
                              placeholder={" "}
                              readOnly
                            />
                          </Accordion>
                        </div>
                      </div>
                    ),
                  },
                  isMachineDocumentationEnabled && {
                    id: "documentation",
                    label:
                      intl?.messages?.machines?.machineDetails.titleTabs
                        .documentation,
                    content: (
                      <>
                        {resource?.template &&
                          resource?.detachedFromTemplate?.documentation ===
                            MACHINE_DOCUMENTATION_STATUSES.ATTACHED &&
                          (!resource.template?.documentFolders?.externalId ? (
                            <ContentLoading />
                          ) : (
                            <BoxUIElement
                              key={
                                resource.template?.documentFolders?.externalId
                              }
                              token={user?.foldersAccessToken}
                              entityId={
                                resource.template?.documentFolders?.externalId
                              }
                              rootFolderId={
                                resource.template?.documentFolders?.externalId
                              }
                              entityName={resource?.name}
                              canUpload={false}
                              canRename={false}
                              canDelete={false}
                              canCreateNewFolder={false}
                            />
                          ))}
                        {(!resource?.template ||
                          (resource?.template &&
                            resource.detachedFromTemplate.documentation ===
                              MACHINE_DOCUMENTATION_STATUSES.DETACHED)) &&
                          (!resource?.documentFolders?.externalId ? (
                            <ContentLoading />
                          ) : (
                            <BoxUIElement
                              key={resource.documentFolders?.externalId}
                              token={user?.foldersAccessToken}
                              entityId={resource.documentFolders?.externalId}
                              rootFolderId={
                                resource.documentFolders?.externalId
                              }
                              entityName={resource?.name}
                              canUpload={false}
                              canRename={false}
                              canDelete={false}
                              canCreateNewFolder={false}
                            />
                          ))}
                      </>
                    ),
                  },
                  isHierarchyEnabled && {
                    id: "sub-assets",
                    label: `${intl?.messages?.hierarchy?.assets?.subAssets} (${
                      resource?.totalChildrenCount ?? 0
                    })`,
                    content: <SubAssets asset={resource} intl={intl} />,
                  },
                  !isHierarchyEnabled &&
                    isComponentsPaid && {
                      id: "components",
                      label: `${
                        intl?.messages?.machines?.machineDetails.titleTabs
                          .components
                      } (${totalCount ?? 0})`,
                      content: (
                        <ComponentsTab
                          components={components}
                          intl={intl}
                          machine={resource}
                          searchTerm={componentSearchTerm}
                          setSearchTerm={setComponentSearchTerm}
                          loading={componentsLoading}
                          totalCount={totalCount}
                          handleFetchMore={handleFetchMore}
                        />
                      ),
                    },
                  user?.oem?.paidFeatures.includes(
                    paidFeaturesRef?.workManagement,
                  ) && {
                    id: "history",
                    label:
                      intl?.messages?.machines?.machineDetails.titleTabs
                        .history,
                    content: <MachineHistory intl={intl} machine={resource} />,
                  },
                  {
                    id: "parts",
                    label:
                      intl?.messages?.machines?.machineDetails.titleTabs.parts,
                    rightContent: resourcePartsLoading ? (
                      <div className="loader" />
                    ) : (
                      `(${resourceParts?.length || 0})`
                    ),
                    content: (
                      <div className="spare-part-tab-content">
                        <div className="u-flex u-items-center u-justify-between parts-table-top">
                          <Button
                            variant={BUTTON_VARIANTS.LINK}
                            text={messages?.parts?.partsRequest}
                            leadingIcon={<Plus size={12} weight="bold" />}
                            onClick={() => createWorkOrder(true)}
                          />
                          <div className="max-lg:hidden">
                            <SearchInput
                              onChange={handleSearch}
                              value={partSearchTerm}
                              placeholder={
                                intl?.messages?.parts
                                  ?.machinePartTabSearchPlaceholder
                              }
                            />
                          </div>
                        </div>
                        {/* MobileSearch component is only for mobile view */}
                        <MobileSearch
                          onChange={handleSearch}
                          value={partSearchTerm}
                          className="spart-part-mobile-search"
                          placeholder={
                            intl?.messages?.parts
                              ?.machinePartTabSearchPlaceholder
                          }
                        />
                        <PartsTableWithPreview
                          loading={resourcePartsLoading}
                          hasInventoryParts={resourceParts?.length > 0}
                          notFoundMessage={
                            intl?.messages?.parts[
                              isHierarchyEnabled
                                ? "noPartsFoundAsset"
                                : "noPartsFound"
                            ]
                          }
                          intl={intl}
                          filteredParts={filteredMchineParts}
                        />
                      </div>
                    ),
                  },
                  isPreventiveMaintenanceEventsEnabled &&
                    events?.length > 0 &&
                    !fetchingEvents && {
                      id: "preventive-maintenance",
                      label: `${intl?.messages?.machines?.machineDetails.titleTabs.preventiveMaintenance} (${events?.length})`,
                      content: (
                        <PreventiveMaintenance events={events} machineId={id} />
                      ),
                    },
                  is3DModelPaid && {
                    id: "3d-model",
                    label:
                      intl?.messages?.machines?.machineDetails.titleTabs._3D,
                    content: resource?._3dModelUrl ? (
                      <Machine3DTab
                        machine={resource}
                        url={resource?._3dModelUrl}
                        onRequestPart={(label) => createWorkOrder(true, label)}
                      />
                    ) : (
                      <BodyText size={BODY_TEXT_SIZES.SMALL}>
                        {
                          intl?.messages?.machines?.machineDetails._3DTab
                            .no3DModelMessage
                        }
                      </BodyText>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        hideFooter
        isOpen={showPreview}
        onClose={handlePreviewClose}
        title={selectedPart?.name}
        contentClassName="pt-md"
      >
        <PartsPreview data={selectedPart} machine={resource} />
      </Drawer>
    </>
  );
};

export default SingleMachine;
