import React from "react";
import { Waypoint } from "react-waypoint";

import { NotFoundComponent } from "~/components/_notFoundMessage";
import AssetRow from "~/components/asset/AssetRow";
import ContentLoading from "~/components/ContentLoading";
import MobileSearch from "~/components/header/MobileSearch";
import { ITEMS_PER_PAGE } from "~/constants/global";
import { NoUserAssignedIcon } from "~/icons";
import { useGetAssetsByParams } from "~/services/asset";
import { WaypointEventType } from "~/types/waypointEvent";

const SubAssets = ({ asset, intl }) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  const {
    assets: subAssets,
    loading: subAssetsLoading,
    handleFetchMore: handleFetchMoreSubAssets,
    totalCount: subAssetsTotalCount,
  } = useGetAssetsByParams({
    where: {
      ...(searchTerm
        ? { parentAssetId: asset._id }
        : { directParentAssetId: asset._id }),
      searchQuery: searchTerm,
    },
    limit: ITEMS_PER_PAGE,
    skip: 0,
  });

  const handleScrollToBottom = (event: WaypointEventType) => {
    if (
      subAssets.length >= ITEMS_PER_PAGE &&
      !subAssetsLoading &&
      event?.previousPosition !== Waypoint.above &&
      subAssets.length < subAssetsTotalCount
    ) {
      handleFetchMoreSubAssets({
        limit: ITEMS_PER_PAGE,
        skip: subAssets.length,
      });
    }
  };

  return (
    <>
      <MobileSearch
        onChange={(value) => setSearchTerm(value)}
        value={searchTerm}
        className="production-line-machines-search"
        isMobileElement={false}
        placeholder={intl?.messages?.machines?.searchPlaceholder}
      />

      {subAssetsLoading && !subAssets.length && (
        <div className="flex justify-center">
          <ContentLoading />
        </div>
      )}

      {Boolean(!searchTerm && !asset.childrenCount && !subAssetsLoading) && (
        <NotFoundComponent
          icon={<NoUserAssignedIcon />}
          title={intl?.messages?.hierarchy?.assets?.noSubAssets}
        />
      )}

      {Boolean(searchTerm && !subAssets?.length && !subAssetsLoading) && (
        <NotFoundComponent
          icon={<NoUserAssignedIcon />}
          title={intl.messages?.common?.noResultFound}
        />
      )}

      {!subAssetsLoading && !!subAssets?.length && (
        <div className="flex flex-col space-y-sm">
          {subAssets?.map((subAsset) => (
            <AssetRow
              key={subAsset._id}
              intl={intl}
              asset={subAsset}
              isAssetQuery={!!searchTerm}
            />
          ))}
          {searchTerm && (
            // @ts-ignore
            <Waypoint onEnter={handleScrollToBottom} />
          )}
        </div>
      )}
    </>
  );
};

export default SubAssets;
