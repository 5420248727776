import { gql } from "@apollo/client";

import {
  Asset,
  User,
  Oem,
  Machine,
  Ticket,
  ProductionLine,
  Component,
  WorkOrderType,
  InventoryPart,
  MachineHistory,
} from "~/api/_fragments";

// REMOTE
export const CONENCTION_QUERY = gql`
  query connection {
    connection
  }
`;

export const AUTH_QUERY = gql`
  query authenticate {
    _checkAuth
  }
`;

export const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      oem {
        slug
        urlOem
        paidFeatures
        statuses {
          _id
          label
          color
        }
        ticketTypes {
          ...WorkOrderTypeData
        }
        assetTypes {
          _id
          name
        }
      }
      facility {
        name
        qrCodeAccess
        isProductionLineDocumentationEnabled
        isMachineDocumentationEnabled
        isPreventiveMaintenanceEventsEnabled
      }
      ...UserAuthData
    }
  }
  ${User.fragments.UserAuthData}
  ${WorkOrderType.fragments.WorkOrderTypeData}
`;

export const GET_CURRENT_USER_BOX_ACCESS_TOKEN_QUERY = gql`
  query currentUser {
    currentUser {
      _id
      foldersAccessToken
    }
  }
`;

export const GET_CURRENT_USER_CHAT_ACCESS_TOKEN_QUERY = gql`
  query currentUser {
    currentUser {
      _id
      chatToken
      chatKeys
      chatUUID
      callChannels
    }
  }
`;

export const GET_OEM_BY_QUERY = gql`
  query getOemBySlug($slug: String!) {
    getOemBySlug(slug: $slug) {
      ...OemBasicData
    }
  }
  ${Oem.fragments.OemBasicData}
`;

export const LIST_OWN_CUSTOMER_MACHINES = gql`
  query listOwnCustomerMachines($params: InputQueryParams) {
    listOwnCustomerMachines(params: $params) {
      currentPage
      limit
      skip
      totalCount
      machines {
        ...MachineListData
      }
    }
  }
  ${Machine.fragments.MachineListData}
`;

export const MACHINES = gql`
  query machines($params: InputQueryParams!) {
    machines(params: $params) {
      ...MachineListData
    }
  }
  ${Machine.fragments.MachineListData}
`;

export const GET_OWN_CUSTOMER_MACHINE = gql`
  query getOwnCustomerMachineById($id: ID!) {
    getOwnCustomerMachineById(id: $id) {
      ...MachineFullData
    }
  }
  ${Machine.fragments.MachineFullData}
`;

export const GET_OWN_CUSTOMER_MACHINE_PARTS = gql`
  query getOwnCustomerMachineById($id: ID!) {
    getOwnCustomerMachineById(id: $id) {
      ...MachinePartsData
    }
  }
  ${Machine.fragments.MachinePartsData}
`;

export const GET_OWN_CUSTOMER_MACHINE_BY_UUID_QUERY = gql`
  query getOwnCustomerMachineByUuid($uuid: ID!) {
    getOwnCustomerMachineByUuid(uuid: $uuid) {
      ...MachineFullData
    }
  }
  ${Machine.fragments.MachineFullData}
`;

export const GET_OWN_CUSTOMER_MACHINE_PARTS_BY_UUID_QUERY = gql`
  query getOwnCustomerMachineByUuid($uuid: ID!) {
    getOwnCustomerMachineByUuid(uuid: $uuid) {
      ...MachinePartsData
    }
  }
  ${Machine.fragments.MachinePartsData}
`;

export const LIST_OWN_FACILITY_OPEN_TICKETS = gql`
  query listOwnFacilityOpenTickets {
    listOwnFacilityOpenTickets {
      ...TicketListData
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const LIST_OWN_CUSTOMER_TICKETS = gql`
  query listOwnCustomerTickets($params: InputQueryParams) {
    listOwnCustomerTickets(params: $params) {
      currentPage
      limit
      skip
      totalCount
      tickets {
        ...TicketListData
      }
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const LIST_OWN_FACILITY_CLOSED_TICKETS = gql`
  query listOwnFacilityClosedTickets {
    listOwnFacilityClosedTickets {
      ...TicketListData
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const GET_OWN_FACILITY_TICKET_BY_ID = gql`
  query getOwnFacilityTicketById($id: ID!) {
    getOwnFacilityTicketById(id: $id) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const LIST_OWN_OEM_MACHINE_TICKET_HISTORY_BY_ID_QUERY = gql`
  query listOwnOemMachineTicketHistoryById($id: ID!) {
    listOwnOemMachineTicketHistoryById(id: $id) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const GET_MACHINE_FOLDER_ID_QUERY = gql`
  query getMachineFolderId($machineId: ID!) {
    getMachineFolderId(machineId: $machineId)
  }
`;

export const GET_OWN_OEM_TEMPLATE_FOLDERS_BY_ID = gql`
  query getOwnOemTemplateFolderStructureById($templateId: ID!) {
    getOwnOemTemplateFolderStructureById(templateId: $templateId) {
      _id
      externalFolderId
      internalFolderId
    }
  }
`;

export const GET_MACHINE_FOLDERS_BY_ID = gql`
  query getMachineFoldersIds($machineId: ID!) {
    getMachineFoldersIds(machineId: $machineId) {
      _id
      externalFolderId
      internalFolderId
    }
  }
`;

export const LIST_PREVENTIVE_MAINTENANCE_EVENTS = gql`
  query listPreventiveMaintenanceEvents($params: InputQueryParams!) {
    listPreventiveMaintenanceEvents(params: $params) {
      _id
      title
      startDate
      eventDate
      frequency
      repeatInNumber
      repeatIn
      ticketCreationNumber
      ticketCreationIn
      description
      nextCreationDate
    }
  }
`;

export const LIST_OWN_CUSTOMER_PRODUCTION_LINES_QUERY = gql`
  query listOwnCustomerProductionLines($params: InputQueryParams) {
    listOwnCustomerProductionLines(params: $params) {
      currentPage
      limit
      skip
      totalCount
      productionLines {
        ...ProductionLineListData
      }
    }
  }
  ${ProductionLine.fragments.ProductionLineListData}
`;

export const GET_OWN_CUSTOMER_PRODUCTION_LINE_BY_ID_QUERY = gql`
  query getOwnCustomerProductionLineById($id: ID!) {
    getOwnCustomerProductionLineById(id: $id) {
      ...ProductionLineData
    }
  }
  ${ProductionLine.fragments.ProductionLineData}
`;

export const LIST_OWN_CUSTOMER_COMPONENTS_QUERY = gql`
  query listOwnCustomerComponents($params: InputQueryParams) {
    listOwnCustomerComponents(params: $params) {
      currentPage
      limit
      skip
      totalCount
      components {
        ...ComponentData
      }
    }
  }
  ${Component.fragments.ComponentData}
`;

export const GET_OWN_CUSTOMER_COMPONENT_BY_ID_QUERY = gql`
  query getOwnCustomerComponentById($id: ID!) {
    getOwnCustomerComponentById(id: $id) {
      ...ComponentDetailData
    }
  }
  ${Component.fragments.ComponentDetailData}
`;

export const GET_OWN_CUSTOMER_COMPONENT_PARTS_BY_ID_QUERY = gql`
  query getOwnCustomerComponentById($id: ID!) {
    getOwnCustomerComponentById(id: $id) {
      ...ComponentPartsData
    }
  }
  ${Component.fragments.ComponentPartsData}
`;

export const GET_SINGLE_LIST_TICKET_BY_ID_QUERY = gql`
  query getOwnFacilityTicketById($id: ID!) {
    getOwnFacilityTicketById(id: $id) {
      ...TicketListData
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const GET_CUSTOMER_INVENTORY_PART = gql`
  query getCustomerInventoryPart($id: ID!) {
    getCustomerInventoryPart(id: $id) {
      ...InventoryPartFullData
    }
  }
  ${InventoryPart.fragments.InventoryPartFullData}
`;

export const LIST_OWN_OEM_MACHINE_HISTORY = gql`
  query listOwnOemMachineHistory($params: InputQueryParams!) {
    listOwnOemMachineHistory(params: $params) {
      totalCount
      skip
      limit
      currentPage
      ticketCount
      history {
        ...MachineHistoryData
      }
    }
  }
  ${MachineHistory.fragments.MachineHistoryData}
`;

export const GET_MACHINE_3D_AUTH_TOKEN_QUERY = gql`
  query getMachine3DAuthToken($id: ID!) {
    getMachine3DAuthToken(id: $id)
  }
`;

export const LIST_OWN_CUSTOMER_ASSETS = gql`
  query listOwnCustomerAssets($params: InputQueryParams) {
    listOwnCustomerAssets(params: $params) {
      currentPage
      limit
      skip
      totalCount
      assets {
        ...AssetListData
      }
    }
  }
  ${Asset.fragments.AssetListData}
`;

export const GET_OWN_CUSTOMER_ASSET = gql`
  query getOwnCustomerAssetById($id: ID!) {
    getOwnCustomerAssetById(id: $id) {
      ...AssetFullData
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const GET_OWN_CUSTOMER_ASSET_PARTS = gql`
  query getOwnCustomerAssetById($id: ID!) {
    getOwnCustomerAssetById(id: $id) {
      ...AssetPartsData
    }
  }
  ${Asset.fragments.AssetPartsData}
`;

export const GET_OWN_CUSTOMER_ASSET_BY_UUID_QUERY = gql`
  query getOwnCustomerAssetByUuid($uuid: ID!) {
    getOwnCustomerAssetByUuid(uuid: $uuid) {
      ...AssetFullData
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const GET_OWN_CUSTOMER_ASSET_PARTS_BY_UUID_QUERY = gql`
  query getOwnCustomerAssetByUuid($uuid: ID!) {
    getOwnCustomerAssetByUuid(uuid: $uuid) {
      ...AssetPartsData
    }
  }
  ${Asset.fragments.AssetPartsData}
`;

export const GET_APP_CONFIG = gql`
  query getAppConfig {
    getAppConfig {
      _id
      features
    }
  }
`;
