import { Fragment, useState } from "react";
import { Waypoint } from "react-waypoint";

import ContentLoading from "~/components/ContentLoading";
import MachineListItem from "~/components/machine/MachineListItem";
import { ITEMS_BEFORE_PAGE, ITEMS_PER_PAGE } from "~/constants/global";
import { useGetAssetsByParams } from "~/services/asset";
import { WaypointEventType } from "~/types/waypointEvent";

const AssetRow = ({
  asset,
  intl,
  isAssetQuery = false,
}: {
  asset;
  intl;
  isAssetQuery?: boolean;
}) => {
  const [expandChildren, setExpandChildren] = useState(false);

  const {
    assets: subAssets,
    loading: subAssetsLoading,
    handleFetchMore: handleFetchMoreSubAssets,
    totalCount: subAssetsTotalCount,
  } = useGetAssetsByParams({
    where: { directParentAssetId: asset._id },
    limit: ITEMS_PER_PAGE,
    skip: 0,
    skipCondition: !expandChildren || !asset.childrenCount || isAssetQuery,
  });

  const handleScrollToBottom = (event: WaypointEventType) => {
    if (
      subAssets.length >= ITEMS_PER_PAGE &&
      !subAssetsLoading &&
      event?.previousPosition !== Waypoint.above &&
      subAssets.length < subAssetsTotalCount
    ) {
      handleFetchMoreSubAssets({
        limit: ITEMS_PER_PAGE,
        skip: subAssets.length,
      });
    }
  };

  return (
    <>
      <MachineListItem
        expanded={expandChildren}
        isAsset
        intl={intl}
        inline
        isAssetQuery={isAssetQuery}
        machine={asset}
        setExpanded={() => setExpandChildren((prev) => !prev)}
        showExpandIcon={!!asset.childrenCount}
      />

      {!isAssetQuery &&
        !!asset.childrenCount &&
        expandChildren &&
        (subAssetsLoading ? (
          <div className="mt-sm flex justify-center">
            <ContentLoading />
          </div>
        ) : (
          <div className="mt-sm ml-lg flex">
            <div className="w-px bg-tertiary flex-shrink-0" />
            <div className="ml-lg flex flex-col w-full space-y-sm">
              {subAssets.map((subAsset, index) => (
                <Fragment key={subAsset._id}>
                  <AssetRow
                    asset={subAsset}
                    intl={intl}
                    isAssetQuery={isAssetQuery}
                  />

                  {!subAssetsLoading &&
                    subAssetsTotalCount > subAssets.length &&
                    index === subAssets.length - ITEMS_BEFORE_PAGE && (
                      // @ts-ignore
                      <Waypoint onEnter={handleScrollToBottom} />
                    )}
                </Fragment>
              ))}
            </div>
          </div>
        ))}
    </>
  );
};

export default AssetRow;
