import { useState } from "react";

export const TAG_COLORS = {
  PINK: "pink",
  VIOLET: "violet",
  PURPLE: "purple",
  MINT: "mint",
  MINT_LIGHT: "mintLight",
  BROWN: "brown",
  GREEN: "green",
  RED: "red",
  ORANGE: "orange",
  BLUE: "blue",
  GREY: "grey",
} as const;

export type TagColorType = (typeof TAG_COLORS)[keyof typeof TAG_COLORS];

const COLOR_STYLES: Record<TagColorType, string> = {
  [TAG_COLORS.PINK]: "bg-pink-lightest text-pink",
  [TAG_COLORS.VIOLET]: "bg-violet-lightest text-violet",
  [TAG_COLORS.PURPLE]: "bg-purple-lightest text-purple",
  [TAG_COLORS.MINT]: "bg-mint-light text-mintBlue",
  [TAG_COLORS.MINT_LIGHT]: "bg-mint-lightest text-mintBlue",
  [TAG_COLORS.BROWN]: "bg-brown-lightest text-brown",
  [TAG_COLORS.GREEN]: "bg-success-lighter text-success",
  [TAG_COLORS.RED]: "bg-danger-lightest text-danger",
  [TAG_COLORS.ORANGE]: "bg-warning-lightest text-warning",
  [TAG_COLORS.BLUE]: "bg-brand-lightest text-brand",
  [TAG_COLORS.GREY]: "bg-accent text-secondary",
};

const HOVER_COLOR_STYLES: Record<TagColorType, string> = {
  [TAG_COLORS.PINK]:
    "hover:bg-pink hover:text-inverse group-hover:bg-pink group-hover:text-inverse",
  [TAG_COLORS.VIOLET]:
    "hover:bg-violet hover:text-inverse group-hover:bg-violet group-hover:text-inverse",
  [TAG_COLORS.PURPLE]:
    "hover:bg-purple hover:text-inverse group-hover:bg-purple group-hover:text-inverse",
  [TAG_COLORS.MINT]:
    "hover:bg-mint hover:text-inverse group-hover:bg-mint group-hover:text-inverse",
  [TAG_COLORS.MINT_LIGHT]:
    "hover:bg-mint hover:text-inverse group-hover:bg-mint group-hover:text-inverse",
  [TAG_COLORS.BROWN]:
    "hover:bg-brown hover:text-inverse group-hover:bg-brown group-hover:text-inverse",
  [TAG_COLORS.GREEN]:
    "hover:bg-success hover:text-inverse group-hover:bg-success group-hover:text-inverse",
  [TAG_COLORS.RED]:
    "hover:bg-danger hover:text-inverse group-hover:bg-danger group-hover:text-inverse",
  [TAG_COLORS.ORANGE]:
    "hover:bg-warning hover:text-inverse group-hover:bg-warning group-hover:text-inverse",
  [TAG_COLORS.BLUE]:
    "hover:bg-brand-dark hover:text-inverse group-hover:bg-brand-dark group-hover:text-inverse",
  [TAG_COLORS.GREY]:
    "hover:bg-secondary hover:text-inverse group-hover:bg-secondary group-hover:text-inverse",
};

type TagPropsType = {
  label: string;
  color?: TagColorType;
  className?: string;
  isUpperCase?: boolean;
  isHoverable?: boolean;
  style?: React.CSSProperties;
  leadingIcon?: React.JSX.Element;
  trailingIcon?: React.JSX.Element;
};

const Tag = ({
  label,
  color = null,
  className = "",
  isUpperCase = true,
  isHoverable = true,
  style = null,
  leadingIcon = null,
  trailingIcon = null,
}: TagPropsType) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => setIsHovered(true);

  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className={`flex items-center justify-center gap-2xs rounded h-2xl px-xs py-2xs transition-all duration-200 ${
        color ? COLOR_STYLES[color] : ""
      } ${isHoverable && color ? HOVER_COLOR_STYLES[color] : ""} ${className}`}
      style={
        style
          ? isHoverable && isHovered
            ? {
                color: "#FFFFFF",
                backgroundColor: style.color,
              }
            : style
          : null
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {leadingIcon}
      <span
        className={`font-manrope text-xs font-bold truncate ${
          isUpperCase ? "uppercase" : ""
        }`}
      >
        {label}
      </span>
      {trailingIcon}
    </div>
  );
};

export default Tag;
